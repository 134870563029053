import React, { useState, useEffect, Component } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";

const CreditNoteApproval = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("approvals");
  const [creditNoteList, setCreditNoteList] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const headercolumns = ["Credit Note No", "Invoice No", "Invoiced Amount", "Customer", "Select", "View", "Remark", "Booking No", "View Documents"];
  const columns = ["credit_note_number", "invoice_number", "invoice_amount", "customer_name"];
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentItems = creditNoteList !== undefined ? creditNoteList.slice(indexOfFirstPost, indexOfLastPost) : undefined;

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    fetch(`${BASE_URL}${pageComponent}/pending_data`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCreditNoteList(data.pending_credit_note_numbers);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [authtoken, pageComponent]);

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (selectedRows === "") {
      toast.error("Please Select Credit Note", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const datalist = [];
      selectedRows.forEach((ivoItem) => {
        const invoiceItems = {
          approval_status: 1,
          credit_note_id: ivoItem,
        }
        datalist.push(invoiceItems);
      });

      const selectedInvoice = {
        credit_note_id_values: datalist,
      };
      axios
        .post(
          `${BASE_URL}credit_note/approve`, JSON.stringify(selectedInvoice, null, 2),
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setTimeout(() => {
            window.location.reload(); // Reload the page after deletion
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
          alert(error);
        })
    }
  };

  const handleReject = async (event) => {
    event.preventDefault();
    if (selectedRows === "") {
      toast.error("Please Select Booking", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const datalist = [];
      selectedRows.forEach((ivoItem) => {
        const invoiceItems = {
          approval_status: 2,
          credit_note_id: ivoItem,
        }
        datalist.push(invoiceItems);
      });

      const selectedInvoice = {
        credit_note_id_values: datalist,
      };
      axios
        .post(
          `${BASE_URL}credit_note/approve`, JSON.stringify(selectedInvoice, null, 2),
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          alert(error);
        })
    }
  };

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row>
        <Table className="mt-2" responsive hover size="sm" striped="columns">
          <thead>
            <tr>
              {columns !== null
                ? headercolumns.map((column) => (
                  <th className="text-center" key={column.id}>
                    {column}
                  </th>
                ))
                : ""}
            </tr>
          </thead>
          <tbody>
            {currentItems !== undefined ? (
              currentItems.map((row, index) => (
                <tr key={row.credit_note_id}>
                  {columns.map((column) => (
                    <td className="text-center" key={column}>
                      {row[column]}
                    </td>
                  ))}
                  <td className="text-center">
                    <input
                      inline
                      label="1"
                      name="group1"
                      type={"checkbox"}
                      className="text-center"
                      onChange={(event) => handleCheckboxChange(event, row.credit_note_id)}
                    />
                  </td>
                  <td>
                    <Button className="ms-3" variant="primary" type="submit">
                      View
                    </Button>
                  </td>
                  <td>
                    <input type="text"></input>
                  </td>
                  <td>{row["booking_number"]}</td>
                  <td>
                    <Button className="ms-3" variant="primary" type="submit">
                      View Document
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </tbody>
        </Table>
        <ReactPaginate
          onPageChange={paginate}
          pageCount={creditNoteList !== undefined ? Math.ceil(creditNoteList.length / postsPerPage) : ""}
          previousLabel={"Prev"}
          nextLabel={"Next"}
          containerClassName={"pagination"}
          pageLinkClassName={"page-number"}
          previousLinkClassName={"page-number"}
          nextLinkClassName={"page-number"}
          activeLinkClassName={"active"}
        />
      </Row>
      <Row className="text-center mt-3 pb-3">
        <Col className="text-end">
          {props.selectedAuthority && props.selectedAuthority.approve === 1 ?
            (<>
              <Button className="ms-3 p-2" variant="primary" onClick={handleSave}>
                Approve
              </Button>
              <Button className="ms-3 p-2" variant="primary" onClick={handleReject}>
                Reject
              </Button>
            </>) : null
          }

        </Col>
      </Row>
    </Container>
  );
};

export default CreditNoteApproval;
