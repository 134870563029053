import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Cookies from "js-cookie";
import BASE_URL from '../../config';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectDropdown from '../../SelectDropdown';
import MultiInputs from '../../CommonComponents/MultiInputs';

const ManifestDetails = (props) => {
    const storedToken = Cookies.get("authToken");
    const storedUser = Cookies.get("authUser");
    const [authtoken, setAuthToken] = useState(storedToken || "");
    const [authUser, setAuthUser] = useState(storedUser || "");
    const [pageComponent, setPageComponent] = useState("manifest");
    const [blNumList, setBlNumList] = useState(undefined);
    const [selectedBLNo, SetSelectedBLNo] = useState("");

    const [manifestDate, setManifestDate] = useState('');
    const [nowManifestDate, setNowManifestDate] = useState('');

    // === Master BL NO === //

    useEffect(() => {
        fetch(`${BASE_URL}manifest/all`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setBlNumList(data.manifest_data || []);
            })
            .catch((error) => {
                alert(error);
            });
    }, [authtoken]);

    useEffect(() => {
        if (props.pageValuesData) {
            const date = props.pageValuesData.manifest_date;
            setManifestDate(date ? date.split(' ')[0] : '');
            setNowManifestDate(date ? date.split(' ')[0] : '');
        }
    }, [props.pageValuesData]);

    const bLNoOptions = blNumList ?
        blNumList.map((bLOptionItem) => ({
            value: bLOptionItem.billing_number_id,
            label: bLOptionItem.master_billing_number,
        })) : [];

    const valueToMsterBLNo = (event) => {
        SetSelectedBLNo(event.value);
        fetch(`${BASE_URL}${pageComponent}/search/${event.value}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.manifest_data.manifest_header_data !== undefined) {
                    props.selectBLDataSet(data.manifest_data.manifest_header_data);
                    props.setNewManifestMode(false);
                } else if (data.manifest_data !== undefined) {
                    props.selectBLDataSet(data.manifest_data);
                    props.setNewManifestMode(true);
                }

                if (data.manifest_data.manifest_container_data !== undefined && data.manifest_data.manifest_container_data.length > 0) {
                    props.selectedDableData(data.manifest_data.manifest_container_data);
                } else if (data.manifest_data.container_numbers_data !== undefined) {
                    props.selectedDableData(data.manifest_data.container_numbers_data);
                } else {
                    props.selectedDableData(undefined);
                    props.setNewManifestMode(true);
                }

                if (event.value !== undefined) {
                    props.dropdownValueHandle("billing_number_id", event.value);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    // === Service Options === //
    const serviceOptions = [{ value: "BK", label: "Bk" }, { value: "LCL", label: "LCL" }, { value: "DOOR", label: "DOOR" }];

    const dateVal = (e) => {
        const formattedDate = new Date(e).toISOString().split('T')[0];
        setNowManifestDate(formattedDate);
        props.pageValueHadle({ target: { name: 'manifest_date', value: formattedDate } });
    };

    const valueServiceOrigin = (event) => {
        props.setServiceOrgin("service_origin", event.value);
    }

    const valueServiceDestination = (event) => {
        props.setServiceOrgin("service_destination", event.value);
    }
    return (
        <Container fluid style={{ boxShadow: 'none', padding: '0' }}>
            <ToastContainer autoClose={2000} />
            <Row>
                <Col>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>BL Number <span className="RQField">*</span></Form.Label>
                                    <SelectDropdown
                                        options={bLNoOptions}
                                        onChange={valueToMsterBLNo}
                                        name={"master_billing_number_id"}
                                        value={selectedBLNo !== "" ? bLNoOptions.find(option => option.value === selectedBLNo) : null}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Booking No </Form.Label>
                                    <Form.Control type="text" disabled="true" placeholder="" autoComplete="off" name="Hbl vessel_code" value={props.pageValuesData.booking_number ? props.pageValuesData.booking_number : ""} onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Date </Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder=""
                                        autoComplete="off"
                                        name="manifest_date"
                                        value={nowManifestDate || ''}
                                        onChange={e => dateVal(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label className="">Vessel</Form.Label><br />
                                    <Row>
                                        <Col>
                                            <Form.Control type="text" placeholder="" value={props.pageValuesData.vessel_code ? props.pageValuesData.vessel_code : ""} onChange={props.pageValueHadle} autoComplete="off" name="vessel_code" />
                                        </Col>
                                        <Col>
                                            <Form.Control type="text" placeholder="" value={props.pageValuesData.vessel_description ? props.pageValuesData.vessel_description : ""} onChange={props.pageValueHadle} autoComplete="off" name="vessel_description" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label className="">Voyage</Form.Label><br />
                                    <Row>
                                        <Col>
                                            <Form.Control type="text" placeholder="" value={props.pageValuesData.voyage_code ? props.pageValuesData.voyage_code : ""} onChange={props.pageValueHadle} autoComplete="off" name="voyage_code" />
                                        </Col>
                                        <Col>
                                            <Form.Control type="text" placeholder="" value={props.pageValuesData.voyage_description ? props.pageValuesData.voyage_description : ""} onChange={props.pageValueHadle} autoComplete="off" name="voyage_description" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label className="">Port of Loading</Form.Label><br />
                                    <Row>
                                        <Col>
                                            <Form.Control type="text" placeholder="" value={props.pageValuesData.loading_port_code ? props.pageValuesData.loading_port_code : ""} onChange={props.pageValueHadle} autoComplete="off" name="loading_port_code" />
                                        </Col>
                                        <Col>
                                            <Form.Control type="text" placeholder="" value={props.pageValuesData.loading_port_description ? props.pageValuesData.loading_port_description : ""} onChange={props.pageValueHadle} autoComplete="off" name="loading_port_description" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label className="">Port of Discharge</Form.Label><br />
                                    <Row>
                                        <Col>
                                            <Form.Control type="text" placeholder="" value={props.pageValuesData.delivery_port_code ? props.pageValuesData.delivery_port_code : ""} onChange={props.pageValueHadle} autoComplete="off" name="delivery_port_code" />
                                        </Col>
                                        <Col>
                                            <Form.Control type="text" placeholder="" value={props.pageValuesData.delivery_port_description ? props.pageValuesData.delivery_port_description : ""} onChange={props.pageValueHadle} autoComplete="off" name="delivery_port_description" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Place of Delivery</Form.Label>
                                    <Form.Control type="text" placeholder="" value={props.pageValuesData.place_of_delivery ? props.pageValuesData.place_of_delivery : ""} onChange={props.pageValueHadle} autoComplete="off" name="place_of_delivery" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Place of Loading</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={props.pageValuesData.loading_port_description ? props.pageValuesData.loading_port_description : ""} onChange={props.pageValueHadle} placeholder="" name="loading_port_description" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Shipper <span className="RQField">*</span></Form.Label>
                                    <MultiInputs nuOfInputs={4} dataObject={props.pageValuesData} inputBaseName="shipper_" onChangeFunction={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Row>
                                        <Col>
                                            <Form.Label>Consignee <span className="RQField">*</span></Form.Label></Col>
                                        <Col>
                                            <Form.Check
                                                value="design"
                                                type="radio"
                                                name="printType"
                                                label="Print in DO"
                                            />
                                        </Col>
                                    </Row>
                                    <MultiInputs nuOfInputs={4} dataObject={props.pageValuesData} inputBaseName="consignee_" onChangeFunction={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Row>
                                        <Col>
                                            <Form.Label>Notify Party <span className="RQField">*</span></Form.Label></Col>
                                        <Col>
                                            <Form.Check
                                                value="design"
                                                type="radio"
                                                name="printType"
                                                label="Print in DO"
                                            />
                                        </Col>
                                    </Row>
                                    <MultiInputs nuOfInputs={4} dataObject={props.pageValuesData} inputBaseName="notify_party_" onChangeFunction={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>

                            <Col>
                                <Form.Group className="mb-1">
                                    <Row>
                                        <Col>
                                            <Form.Label>
                                                Notify Party 2
                                            </Form.Label></Col>
                                        <Col>
                                            <Form.Check
                                                value="design"
                                                type="radio"
                                                name="printType"
                                                label="Print in DO"
                                            />
                                        </Col>
                                    </Row>
                                    <MultiInputs nuOfInputs={4} dataObject={props.pageValuesData} inputBaseName="notify_party_1" onChangeFunction={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Row>
                                        <Col>
                                            <Form.Label> Mark & No <span className="RQField">*</span></Form.Label>
                                        </Col>
                                    </Row>
                                    <MultiInputs nuOfInputs={4} dataObject={props.pageValuesData} inputBaseName="marks_numbers_" onChangeFunction={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label> Goods Description </Form.Label>
                                    <MultiInputs nuOfInputs={4} dataObject={props.pageValuesData} inputBaseName="good_description_" onChangeFunction={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Serial No <span className="RQField">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="" value={props.pageValuesData.serial_number ? props.pageValuesData.serial_number : ""} onChange={props.pageValueHadle} autoComplete="off" name="serial_number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Row>
                                        <Col>
                                            <Form.Label>
                                                Remark
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                    <Form.Control as="textarea" value={props.pageValuesData.remark ? props.pageValuesData.remark : ""} onChange={props.pageValueHadle} placeholder="" name="remark" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Gross Weight(kg)</Form.Label>
                                    <Form.Control type="text" placeholder="" value={props.pageValuesData.gross_weight ? props.pageValuesData.gross_weight : ""} onChange={props.pageValueHadle} autoComplete="off" name="gross_weight" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Measurement(M3)<span className="RQField">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="" value={props.pageValuesData.measurement ? props.pageValuesData.measurement : ""} onChange={props.pageValueHadle} autoComplete="off" name="measurement" />
                                </Form.Group>
                            </Col>

                            <Col className="text-center"> <Form.Label >Service</Form.Label></Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Origin</Form.Label>
                                    <SelectDropdown
                                        options={serviceOptions}
                                        onChange={valueServiceOrigin}
                                        name={"service_origin"}
                                        value={props.pageValuesData.service_origin !== "" ? serviceOptions.find(option => option.value === props.pageValuesData.service_origin) : null}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Destination</Form.Label>
                                    <SelectDropdown
                                        options={serviceOptions}
                                        onChange={valueServiceDestination}
                                        name={"service_destination"}
                                        value={props.pageValuesData.service_destination !== "" ? serviceOptions.find(option => option.value === props.pageValuesData.service_destination) : null}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ManifestDetails;