import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import Cookies from "js-cookie";
import BASE_URL from '../config';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectDropdown from '../SelectDropdown';
import ReusableButtonComponent from '../CommonComponents/ReusableButtonComponent';

const BookingMSCDetails = (props) => {
    const storedToken = Cookies.get("authToken");
    const storedUser = Cookies.get("authUser");
    const [authtoken, setAuthToken] = useState(storedToken || "");
    const [authUser, setAuthUser] = useState(storedUser || "");
    const [pageComponent, setPageComponent] = useState('booking_mcs_details');
    const [data, setData] = useState(undefined);
    const [selectedBookingItem, setSelectedBookingItem] = useState('');
    const [bookinNOList, setBookinNOList] = useState(undefined);
    const [customerList, setCustomerList] = useState(undefined);
    const [selectedsustomers, setSelectedsustomers] = useState(undefined);
    const [curruntBookingNO, setCurruntBookingNO] = useState('');
    const [selectedCUS, setSelectedCUS] = useState(['']);
    const [cusCode, setCusCode] = useState('');
    const [volume, setVolume] = useState('');
    const [blNUM, setBlNUM] = useState('');
    const [currentCustID, setCurrentCustID] = useState('');
    const [container, setcontainer] = useState('');
    const [reMark, setReMark] = useState('');
    const [emptyInput, setEmptyInput] = useState(false);
    const [emptyVolume, setEmptyVolume] = useState(false);
    const [emptyCustomers, setEmptyCustomers] = useState(false);
    const [emptyBl, setEmptyBl] = useState(false);
    const [emptyContainers, setEmptyContainers] = useState(false);
    const [emptyRemark, setEmptyRemark] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}${pageComponent}/all`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${authtoken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setBookinNOList(data.booking_m_c_s_details);
            } catch (error) {
                alert(error.message);
            }
        };

        fetchData();
    }, [authtoken, pageComponent]);



    useEffect(() => {
        fetch(`${BASE_URL}customers/dropdown`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setCustomerList(data.customers);
            })
            .catch(error => {
                alert(error());
            })
    }, [authtoken]);

    const setBookingDetails = (selectedID) => {
        fetch(`${BASE_URL}${pageComponent}/search/${selectedID}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        }).then(response => response.json()).then(data => {
            setSelectedsustomers({ value: "", label: "" });
            setVolume(data.booking_m_c_s_details[0].customer_container_volume ? data.booking_m_c_s_details[0].customer_container_volume : "");
            setBlNUM(data.booking_m_c_s_details[0].bill_numbers ? data.booking_m_c_s_details[0].bill_numbers : "");
            setcontainer(data.booking_m_c_s_details[0].container_number ? data.booking_m_c_s_details[0].container_number : "");
            setReMark(data.booking_m_c_s_details[0].remark ? data.booking_m_c_s_details[0].remark : "");
            const selectedbookingNoOptions = data.booking_m_c_s_details?.map((bookingNoOptionItem) => ({
                value: bookingNoOptionItem.customer_id,
                label: bookingNoOptionItem.customer_name,
            })) || [];
            setSelectedsustomers(selectedbookingNoOptions);
            setCurrentCustID(selectedbookingNoOptions[0].value);
        })
    }


    const selectBookingNO = (event) => {
        setCurruntBookingNO(event.value);
        setSelectedCUS(event.value);
        setBookingDetails(event.value);
    }

    const selectCusId = (event) => {
        setCusCode(event.value);
    }

    const handleSave = async (event) => {
        event.preventDefault();
        setEmptyVolume(false);
        const inputData = { booking_header_id: curruntBookingNO, customer_id: currentCustID, bill_numbers: blNUM, container_number: container, customer_container_volume: volume, remark: reMark };
        if (volume.length === 0) {
            toast.error("Volume can not be empty", {
                position: toast.POSITION.TOP_RIGHT
            });
            setEmptyVolume(true);
            setEmptyInput(true);
        } else {
            setEmptyVolume(false);
        }

        if (currentCustID.length === 0) {
            toast.error("Customer can not be empty", {
                position: toast.POSITION.TOP_RIGHT
            });
            setEmptyCustomers(true);
            setEmptyInput(true);
        } else {
            setEmptyCustomers(false);
        }

        if (blNUM.length === 0) {
            toast.error("BL Number can not be empty", {
                position: toast.POSITION.TOP_RIGHT
            });
            setEmptyBl(true);
            setEmptyInput(true);
        } else {
            setEmptyBl(false);
        }

        if (container.length === 0) {
            toast.error("Container can not be empty", {
                position: toast.POSITION.TOP_RIGHT
            });
            setEmptyContainers(true);
            setEmptyInput(true);
        } else {
            setEmptyContainers(false);
        }

        if (reMark.length === 0) {
            toast.error("Remark can not be empty", {
                position: toast.POSITION.TOP_RIGHT
            });
            setEmptyRemark(true);
            setEmptyInput(true);
        } else {
            setEmptyRemark(false);
        }

        if (emptyInput !== true) {
            const successCallback = () => {
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            };
            axios.post(`${BASE_URL}${pageComponent}/store`, inputData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authtoken}`,

                },
            })
                .then(response => {
                    toast.success('Successfully Saved Data ! ', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    successCallback();
                })
                .catch(error => {
                    toast.error("error", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                });
        } else {

        }
    }

    const bookingNoOptions = bookinNOList ?
        bookinNOList.map((bookinNoOptionItem) => ({
            value: bookinNoOptionItem.booking_header_id,
            label: bookinNoOptionItem.booking_number,

        })) : [];

    const customersoptions = customerList ?
        customerList.map((customerOptionItem) => ({
            value: customerOptionItem.id,
            label: customerOptionItem.customer_name,
        })) : [];

    const setCustomer = (event) => {
        setCurrentCustID(event.value);

    };

    return (
        <Container fluid>
            <ToastContainer autoClose={2000} />
            <Row>
                <Col>
                    <Form className='mt-3 bottom-border pb-3'>
                        <Row >
                            <Col>
                                <Form.Group className="mb-3" controlId="Vessel">
                                    <Form.Label>Booking No</Form.Label>
                                    <SelectDropdown
                                        options={bookingNoOptions}
                                        onChange={selectBookingNO}
                                        // value={
                                        //     vesselCode !== ""
                                        //       ? { value: cusCode, label: selectedCUS }
                                        //       : null
                                        //   }
                                        menuPortalTarget={document.body} // Render the menu outside of the normal parent hierarchy
                                        menuPosition="fixed" // Ensure the menu stays in a fixed position
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formGroupvolum">
                                    <Form.Label>Volume</Form.Label>
                                    <Form.Control type="text" placeholder="" name="countrycode" value={volume} className={emptyVolume === true ? 'border-red2' : ''} onChange={e => setVolume(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="Vessel">
                                    <Form.Label>Customers</Form.Label>

                                    <SelectDropdown
                                        options={selectedsustomers !== undefined ? selectedsustomers : ""}
                                        onChange={setCustomer}
                                        menuPortalTarget={document.body} // Render the menu outside of the normal parent hierarchy
                                        menuPosition="fixed" // Ensure the menu stays in a fixed position
                                        value={selectedsustomers !== undefined ? selectedsustomers.find(option => option.value === currentCustID) : null}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>BL Numbers</Form.Label>
                                    <Form.Control as="textarea" placeholder="BL Nos. here" className={emptyBl === true ? 'border-red2' : ''} value={blNUM} onChange={e => setBlNUM(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Containers</Form.Label>
                                    <Form.Control as="textarea" placeholder="Containers here" className={emptyContainers === true ? 'border-red2' : ''} value={container} onChange={e => setcontainer(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Remark</Form.Label>
                                    <Form.Control as="textarea" placeholder="Remark here" className={emptyRemark === true ? 'border-red2' : ''} value={reMark} onChange={e => setReMark(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='text-center'>
                            <Col className='text-end'>
                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="save"
                                    label="Save"
                                    onClick={handleSave}
                                />

                                {/* <Button className="ms-3" variant="primary" type="submit">
                                    Cancel
                                </Button> */}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </Container>
    );

}


export default BookingMSCDetails;