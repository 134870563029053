import React, { useEffect } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BillTable from "../BillTableView";
import { Container } from "react-bootstrap";
import SelectDropdown from "../SelectDropdown";
import ReusableButtonComponent from "../CommonComponents/ReusableButtonComponent";
import { forEach } from "lodash";

const Invoice = (props) => {
  // Get the current date
  const currentDate = new Date().toISOString().slice(0, 10); // Format: YYYY-MM-DD
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("invoice");
  const [pageData, setPageData] = useState(undefined);
  const [pageValuesData, setpageValuesData] = useState({ invoice_date: currentDate, });
  const [invNo, setInvNo] = useState("");
  const [customerId, setCustomerId] = useState(undefined);
  const [bookingNOList, setBookingNOList] = useState(undefined);
  const [consigneeList, setconsigneeList] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [isAllBooking, setIsAllBooking] = useState(false);
  const [isCustomerCopy, setIsCustomerCopy] = useState(false);
  const [consignee, setConsignee] = useState([]);
  const [selectedTbaleRowID, setSelectedTbaleRowID] = useState("");
  const [curruncyList, setCurruncyList] = useState(undefined);
  const [currCode, setCurrCode] = useState(1);
  const [currDesc, setCurrDesc] = useState("");
  const [payterm, setPayterm] = useState(2);
  const [bookindNo, setBookindNo] = useState("");
  const [iNVDate, setINVDate] = useState("");
  const [eXGRate, setEXGRate] = useState(1);
  const [sVATNO, setsVATNO] = useState("");
  const [vATNO, setVATNO] = useState("");
  const [bLNO, setBLNO] = useState("");
  const [contNO, setContNO] = useState("");
  const [transactionClass, setTransactionClass] = useState(1);
  const [transactionType, setTransactionType] = useState(2);
  const [rateList, setRateList] = useState(undefined);
  const [rateCode, setRateCode] = useState("");
  const [rateDesc, setRateDesc] = useState("");
  const [exgRaterRow, setExgRaterRow] = useState("");
  const [volueRow, setVolueRow] = useState("");
  const [amountRow, setAmountRow] = useState("");
  const [remark, setRemark] = useState("");
  const [USDAmount, setUSDAmount] = useState(0);
  const currentCurrencyName = { currency_description: "USD" };
  const tableItem = { volume: 10, quote: 5 };
  const [totalAmount, setTotalAmount] = useState("");
  const [contact, setContact] = useState("");
  const [selectedCurrType, setSelectedCurrType] = useState("L");
  const [selectedPrintOption, setSelectedPrintOption] = useState("N");

  const [tableItems, setTableItems] = useState([]);
  const [taxList, setTaxList] = useState(undefined);
  const [vatLIst, setVatLIst] = useState([]);
  const [nBTPrecentage, setNBTPrecentage] = useState(0);
  const [vatPrecentage, setVatPrecentage] = useState(0);

  const [feildValidate, setFeildValidate] = useState(true);
  const [createNewInvoice, setCreateNewInvoice] = useState(false);
  const [validateFeildItems, setvalidateFeildItems] = useState([]);
  const [tableValidateFeildItems, setTablevalidateFeildItems] = useState([]);
  const [approvedStatus, setApprovedStatus] = useState("");
  const [incoiveRemark, setINvoiceRemark] = useState("");
  const [bookingNumber, setBookingNumber] = useState("");
  const { selectedAuthority, creditNoteDate, creditNoteNumber, creditNoteAmount } = props;

  const hadleInputValues = (e) => {
    setpageValuesData({ ...pageValuesData, [e.target.name]: e.target.value });
  };

  const constSelectInvoTableHadle = (value) => {
    const dataList = [];
    value.forEach((table_line) => {
      const rateItem = pageData.rates_data.find(
        (item) => item.id === table_line.rate_id
      );
      const curItem = pageData.currency_data.find(
        (item) => item.id === table_line.currency_id
      );
      const tableDataItem = {
        invoice_wise_rate_id: table_line.id,
        RateName: rateItem ? rateItem.rate_description : null,
        rate_id: table_line.rate_id,
        currency_id: table_line.currency_id,
        Currency: curItem ? curItem.currency_description : null,
        exchange_rate: table_line.exchange_rate,
        volume: table_line.volume,
        amount: table_line.amount,
        TotalAmount:
          table_line.amount * table_line.volume * table_line.exchange_rate,
        remark: table_line.remark,
        VATAmount: table_line.amount * table_line.vat_percentage,
        nbt_percentage: table_line.nbt_percentage,
        vat_percentage: table_line.vat_percentage,
        NBTAmount: table_line.amount * table_line.nbt_percentage,
      };

      dataList.push(tableDataItem);
    });

    // Update the state with the modified array
    setTableItems(dataList);
  };

  const selectInvoive = (event) => {
    handleSelectInvoice(event.value);
    setSelectedInvoice(event.value);
  };

  // === validatios === //

  const validateField = (fieldValue, fieldName) => {
    if (typeof fieldValue === "string" && !fieldValue.trim()) {
      setvalidateFeildItems((prevItems) => [...prevItems, fieldName]);
      setFeildValidate(false);
      toast.error(`${fieldName} can not be empty`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      setvalidateFeildItems((prevItems) =>
        prevItems.filter((item) => item !== fieldName)
      );
      setFeildValidate(true);
    }
  };

  const handleSelectInvoice = async (value) => {
    if (value) {
      await axios
        .get(`${BASE_URL}${pageComponent}/single/${value}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        })
        .then((response) => {
          setInvNo(response.data.invoice_header_data.invoice_number)
          setApprovedStatus(response.data.invoice_header_data.approval_status)
          setBookindNo(response.data.invoice_header_data.booking_header_id);
          setBookingNumber(response.data.invoice_header_data.booking_number);
          setINVDate(response.data.invoice_header_data.invoice_date);

          // Update consigneeList with unique customers
          const invoiceconsigner = pageData.customers_data.find(
            (item) => item.id === response.data.invoice_header_data.customer_id
          );
          setconsigneeList([invoiceconsigner]);
          setConsignee(response.data.invoice_header_data.customer_id);
          setCustomerId(response.data.invoice_header_data.customer_id);
          setCurrCode(response.data.invoice_header_data.currency_id);
          setPayterm(response.data.invoice_header_data.payment_term_id);
          setsVATNO(response.data.invoice_header_data.customer_s_vat_number);
          setVATNO(response.data.invoice_header_data.customer_vat_number);
          setBLNO(response.data.invoice_header_data.bill_number);
          setINvoiceRemark(response.data.invoice_header_data.remark);
          setContNO(response.data.invoice_header_data.container_numbers);
          setTransactionClass(response.data.invoice_header_data.transaction_class_id);
          setTransactionType(
            response.data.invoice_header_data.transaction_type_id
          );
          setEXGRate(response.data.invoice_header_data.exchange_rate);
          setSelectedCurrType(response.data.invoice_header_data.invoice_type);
          setSelectedPrintOption(
            response.data.invoice_header_data.print_option
          );
          // setRateDesc(
          //   response.data.invoice_detail.invoice_header_detail[0].RateCode
          // );

          // ==== sent data to table arrange ==== //
          constSelectInvoTableHadle(response.data.invoice_wise_rates_data);

          const updatedPageValues = { ...pageValuesData, customer_id: response.data.invoice_header_data.customer_id };
          setpageValuesData(updatedPageValues);
          setpageValuesData(prevData => ({ ...prevData, ...response.data }));
        });

      // .catch((error) => {
      //   console.error(error);
      // });
    }
  };

  const handleYearBooking = (event) => {
    setIsAllBooking(event.target.checked);
    if (event.target.checked === true) {
      setBookingNOList(pageData !== undefined ? pageData.booking_data : "");
    }
    if (event.target.checked === false) {
      setBookingNOList(pageData !== undefined ? pageData.booking_data : "");
    }
  };

  const handleCustomerCopy = (event) => {
    event.target.checked ? setIsCustomerCopy(true) : setIsCustomerCopy(false);
  };

  const setCurrencyDt = (event) => {
    setCurrCode(event.value);
    setCurrDesc(event.label);
    if (event.label !== "LKR") {
      setSelectedCurrType("F");
    } else {
      setSelectedCurrType("L");
    }
  };

  const setPaytermDt = (event) => {
    setPayterm(event.value);
  };

  const setTransactionClassDt = (event) => {
    setTransactionClass(event.value);
  };

  const setTransactionTypeDt = (event) => {
    setTransactionType(event.value);
  };



  const setVAT = (event) => {
    if (event.target.checked === true) {
      setVatLIst([...vatLIst, event.target.id]);
    }

    if (event.target.checked === false) {
      const updatedArray = vatLIst.filter((item) => item !== event.target.id);
      setVatLIst(updatedArray);
    }
  };

  const passBookingNUM = async (event) => {
    setconsigneeList([]);
    setUSDAmount("");
    setBookindNo(event.value);

    if (event.value) {
      try {
        const response = await axios.get(`${BASE_URL}booking_costing/search/${event.value}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        });

        const bookingData = response.data.booking_wise_rates_table_data;
        const containerData = response.data.booking_wise_container_numbers_table_data;
        setCustomerId(bookingData[0].customer_id);

        // Update pageValuesData with customer_id
        const updatedPageValues = { ...pageValuesData, customer_id: bookingData[0].customer_id };
        setpageValuesData(updatedPageValues);

        setbookingTableData(bookingData);

        // Extract container numbers
        const conNumbers = containerData.map(item => item.container_number).join(",");
        setContNO(conNumbers);

        // Extract unique customers
        const uniqueCustomers = Array.from(new Set(bookingData.map(item => item.customer_id)))
          .map(id => pageData.customers_data.find(customer => customer.id === id));

        // Update consigneeList with unique customers
        setconsigneeList(uniqueCustomers);

        // Optionally update pageValuesData with the entire response data


      } catch (error) {
        console.error("Error fetching booking data:", error);
      }
    }
  };

  const setConsignerDt = async (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setpageValuesData({ ...pageValuesData, customer_id: parseInt(selectedIds) });
    setConsignee(Array.isArray(selectedIds) ? selectedIds : []);

    if (selectedOptions.length > 0) {
      const requests = selectedIds.map((id) =>
        axios.get(`${BASE_URL}${pageComponent}/dropdown/${id}/${bookindNo}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        })
      );

      try {
        const responses = await Promise.all(requests);
        const allData = responses.flatMap(
          (response) => response.data.rate_data
        );
        setbookingTableData(allData);
      } catch (error) {
        console.error("Error fetching booking data:", error);
      }
    } else {
      setbookingTableData([]);
    }
  };

  const setbookingTableData = async (bookingDatatt) => {
    const currentTableDatarow = bookingDatatt.map((tableItem, index) => {
      const currentCustomer = pageData.customers_data.find(
        (item) => item.id === tableItem.customer_id
      );
      const currentRateName = pageData.rates_data.find(
        (item) => item.id === tableItem.rate_id
      );
      const currentCurrencyName = pageData.currency_data.find(
        (item) => item.id === tableItem.currency_id
      );
      const totCost =
        tableItem.exchange_rate * tableItem.volume * tableItem.cost;
      const totQuote =
        tableItem.exchange_rate * tableItem.volume * tableItem.quote;
      const totnbt = totCost * tableItem.nbt_percentage;
      const totVat = totCost * tableItem.vat_percentage;
      const totNbtQuote = totQuote * tableItem.nbt_percentage;
      const totVatQuote = totQuote * tableItem.vat_percentage;
      setCurrCode(tableItem.currency_id);
      if (currentCurrencyName.currency_description !== "LKR") {
        setSelectedCurrType("F");
      } else {
        setSelectedCurrType("L");
      }
      if (currentCurrencyName.currency_description === "USD") {
        setUSDAmount(
          (prevUSDAmount) => prevUSDAmount + tableItem.volume * tableItem.quote
        );
      }

      return {
        rate_id: tableItem.rate_id,
        currency_id: tableItem.currency_id,
        CustName: currentCustomer.customer_name,
        RateName: currentRateName.rate_description,
        exchange_rate: tableItem.exchange_rate,
        volume: tableItem.volume,
        Currency: currentCurrencyName.currency_description,
        amount: tableItem.quote,
        TotalAmount:
          tableItem.exchange_rate * tableItem.volume * tableItem.quote,
        remark: tableItem.rate_remark,
        vat_percentage: tableItem.vat_percentage,
        nbt_percentage: tableItem.nbt_percentage,
        TotalCost: totCost,
        TotalQuote: totQuote,
        Difference: totQuote - totCost,
        TotalNBT: totnbt,
        TotalVAT: totVat,
        NBTAmount: totQuote * tableItem.nbt_percentage,
        VATAmount: totQuote * tableItem.vat_percentage,
      };
    });

    setTableItems(currentTableDatarow);
  };

  const bookingNoOptions = pageData
    ? pageData.booking_data.map((bookinNoOptionItem) => ({
      value: bookinNoOptionItem.id,
      label: bookinNoOptionItem.booking_number,
    }))
    : [];

  const newBookingAction = () => {
    if (bookingNoOptions.length > 0) {
      // const latestBookingNo = bookingNoOptions[bookingNoOptions.length - 1].value;
      const latestBookingNo = bookingNoOptions[0].value;
      setBookindNo(latestBookingNo);
      passBookingNUM({ value: latestBookingNo });
    }
  }

  const invoiceOptions = pageData
    ? pageData.invoice_data.map((invqoiceOptionItem) => ({
      value: invqoiceOptionItem.id,
      label: invqoiceOptionItem.invoice_number,
    }))
    : [];

  useEffect(() => {
    if (invoiceOptions.length > 0) {
      handleSelectInvoice(invoiceOptions[0].value);
    }
  }, [pageData]);

  const customersoptions = consigneeList
    ? consigneeList.map((customerOptionItem) => ({
      value: customerOptionItem.id,
      label: customerOptionItem.customer_name,
    }))
    : [];

  const currencyOptions = pageData
    ? pageData.currency_data.map((currencyOptionItem) => ({
      value: currencyOptionItem.id,
      label: currencyOptionItem.currency_description,
    }))
    : [];

  useEffect(() => {
    if (currentCurrencyName.currency_description === "USD") {
      setUSDAmount(
        (prevUSDAmount) => prevUSDAmount + tableItem.volume * tableItem.quote
      );
    }
  }, [currentCurrencyName.currency_description, tableItem.quote, tableItem.volume]);

  // Function to format number as currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  // Function to format number as Local currency
  const formatLocalCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "LKR",
    }).format(amount);
  };

  // Function to format number as Local currency
  const amountFormat = (amount) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const paytermListOptions = pageData
    ? pageData.payment_terms_to_customer_data.map((paytermOptionItem) => ({
      value: paytermOptionItem.id,
      label: paytermOptionItem.payment_term,
    }))
    : [];

  const transactionClassListOptions = pageData
    ? pageData.transaction_classes_data.map((transactionClassItem) => ({
      value: transactionClassItem.id,
      label: transactionClassItem.transaction_class,
    }))
    : [];

  const transactionTypeListOptions = pageData
    ? pageData.transaction_types_data.map((transactionTypeItem) => ({
      value: transactionTypeItem.id,
      label: transactionTypeItem.transaction_type,
    }))
    : [];

  const ratesOptions = pageData
    ? pageData.rates_data.map((rateItem) => ({
      value: rateItem.id,
      label: rateItem.rate_description,
    }))
    : [];

  const validateTableAddItems = (fieldValue, fieldName) => {
    if (typeof fieldValue === "string" && !fieldValue.trim()) {
      setTablevalidateFeildItems((prevItems) => [...prevItems, fieldName]);
      // setFeildValidate(false);
      toast.error(`${fieldName} can not be empty`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      setTablevalidateFeildItems((prevItems) =>
        prevItems.filter((item) => item !== fieldName)
      );
      // setFeildValidate(true);
    }
    return true;
  };

  const appendTableLine = () => {

    if (tableValidateFeildItems.length === 0) {
      let Amount = 0;
      let totNBT = 0;
      let nbtPrec = 0;
      let vatPrec = 0;
      let percentage = 0;
      let vatAmount = 0;
      let nbtAmount = 0;

      pageData.taxes_data.forEach((vatItem) => {
        pageData.taxes_data.forEach((elementitem) => {
          if (elementitem.tax === vatItem.tax) {
            if (elementitem.tax_rate > 0) {
              percentage = elementitem.tax_rate / 100 * 1;
            } else {
              percentage = 0;
            }
            let tRate = elementitem.tax_rate;
            if (vatItem.tax === "NBT") {
              nbtPrec = percentage;
              setNBTPrecentage(nbtPrec);
              nbtAmount = amountRow * vatPrec;
            } else if (vatItem.tax === 'VAT') {
              vatPrec = elementitem.tax_rate / 100 * 1;
              vatAmount = amountRow * vatPrec;
              setVatPrecentage(vatPrec * 1);
            }
          }
        });
      });

      if (amountRow > 0) {
        appndTableValue(vatAmount * 1, vatPrec * 1, nbtPrec * 1, nbtAmount * 1);
      }
    }
  };

  const setTableValue = async () => {
    validateTableAddItems(rateCode, "Rate");
    validateTableAddItems(currCode, "Currency");
    validateTableAddItems(exgRaterRow, "Exg Rate");
    validateTableAddItems(volueRow, "Volume");
    validateTableAddItems(amountRow, "Amount");
    validateTableAddItems(remark, "Remark");
    appendTableLine();
  };

  const setSelectVATTax = (value) => {
    var checkbox = document.getElementById("VAT");
    if (checkbox) {
      checkbox.checked = value > 0;
    }
  };

  const setSelectNBTTax = (value) => {
    var checkbox = document.getElementById("NBT");
    if (checkbox) {
      checkbox.checked = value > 0;
    }
  };

  const appndTableValue = async (vatAmount, vatPrec, nbtPrec, nbtAmount) => {
    const rateItem = pageData.rates_data.find((item) => item.id === rateCode);
    const selectedCurrency = pageData.currency_data.find(
      (currency) => currency.id === currCode
    );
    const updateTableVal = {
      RateName: rateItem ? rateItem.rate_description : null,
      rate_id: rateCode,
      currency_id: currCode,
      Currency: selectedCurrency.currency_description,
      exchange_rate: exgRaterRow,
      volume: parseInt(volueRow),
      amount: parseInt(amountRow),
      TotalAmount: parseInt(amountRow * volueRow * exgRaterRow),
      remark: remark,
      VATAmount: vatAmount * 1,
      nbt_percentage: nbtPrec * 1,
      vat_percentage: vatPrec * 1,
      NBTAmount: nbtAmount * 1,
    };
    const updateTableLIst = tableItems.concat(updateTableVal);
    setTableItems(tableItems.concat(updateTableVal));

    setTimeout(() => {
      settUpdatedTableDatatoMainObject();
    }, 2000);

  };
  const settUpdatedTableDatatoMainObject = () => {
    setpageValuesData({ ...pageValuesData, booking_wise_rates_table_data: tableItems });

  }
  const calculateTotalAmount = () => {
    const totalAmount = tableItems.reduce((total, tableItem) => total * 1 + tableItem.TotalAmount * 1, 0);
    let x = totalAmount * 1;
    return (Math.round(x * 100) / 100);
  };
  const calculateDollerTotalAmount = () => {
    let dollerTotel = 0;
    tableItems.forEach(tableItem => {

      if (tableItem.Currency === 'USD') {
        dollerTotel = dollerTotel + tableItem.amount * tableItem.volume
      }
    });
    return dollerTotel;
  };

  const RemoveSelectedDataRow = async () => {
    const updateTableLIst = tableItems.filter(
      (obj, index) => index !== selectedTbaleRowID
    );
    setTableItems(updateTableLIst);
  };

  const handlecurrOption = (event) => {
    if (event.target.value === "L") {
      setCurrCode(1);
      setEXGRate(1);
    } else if (event.target.value === "F") {
      setCurrCode(2);
      setEXGRate("");
    }
    setSelectedCurrType(event.target.value);
  };

  const handlePrintOption = (event) => {
    setSelectedPrintOption(event.target.value);
  };

  const selectRateChange = (event) => {
    setRateCode(event.value);
    setRateDesc(event.label);
  };

  const hadleNewInvoice = () => {
    setBLNO("");
    setVATNO("");
    setInvNo("");
    setSelectedInvoice("");
    setCreateNewInvoice(true);
    newBookingAction();
    setApprovedStatus("");
    setBookingNumber("");
    setTransactionType(2)
  };

  const saveINvoice = async () => {
    // Validate all required fields
    await validateField(transactionClass, "Transaction Class");
    await validateField(transactionType, "Transaction Type");
    await validateField(bookindNo, "Booking No");
    await validateField(currentDate, "Inv Date");
    await validateField(consignee, "Consignee");
    await validateField(selectedCurrType, "Invoice Type");
    await validateField(currCode, "Currency");
    await validateField(eXGRate, "Exg Rate");
    await validateField(payterm, "Payment Term");
    await validateField(selectedPrintOption, "Print Option");
    await validateField(bLNO, "BL Nos");
    // await validateField(contNO, "Conta Nos");

    // Check if any of the fields are not empty
    if (bLNO !== "" || bookindNo !== "" || contNO !== "") {
      postDataToSave(); // Proceed to save data
    }
  };

  const postDataToSave = () => {
    // Check if field validation passed
    if (feildValidate) {
      const inputInvoiceData = {
        // Prepare data to be sent to the backend
        booking_header_id: bookindNo,
        customer_id: customerId,
        invoice_type: selectedCurrType,
        currency_id: currCode,
        exchange_rate: eXGRate,
        payment_term_id: payterm,
        print_option: selectedPrintOption,
        customer_s_vat_number: sVATNO,
        customer_vat_number: vATNO,
        bill_number: bLNO,
        container_numbers: contNO,
        transaction_class_id: transactionClass,
        transaction_type_id: transactionType,
        remark: incoiveRemark,
        invoice_wise_rates_table_data: tableItems,
      };

      // Send data to the backend for update/save
      axios
        .post(
          selectedInvoice
            ? `${BASE_URL}${pageComponent}/update/${selectedInvoice}`
            : `${BASE_URL}${pageComponent}/store`,
          inputInvoiceData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authtoken}`,
            },
          }
        )
        .then((response) => {
          if (validateFeildItems.length === 0 && response.status === 200) {
            // If the request is successful and there are no validation errors
            // Show success message
            toast.success("Successfully Updated Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
            // Reload the page after successful operation
            // window.location.reload();
          }
        })
        .catch((error) => {
          // If an error occurs during the request
          console.error(error);
          // Show alert with error message
          alert(error);
          // Log the input data for debugging purposes
        });
    }
  };

  const saveCreditNote = async () => {
    try {
      // Assuming these are state variables or props received from parent component
      const {
        selectedAuthority,
        creditNoteDate,
        creditNoteNumber,
        creditNoteAmount,
        bookindNo, // Replace with appropriate value if applicable
        customerId, // Replace with appropriate value if applicable
        selectedCurrType, // Replace with appropriate value if applicable
        currCode, // Replace with appropriate value if applicable
        eXGRate, // Replace with appropriate value if applicable
        payterm, // Replace with appropriate value if applicable
        selectedPrintOption, // Replace with appropriate value if applicable
        sVATNO, // Replace with appropriate value if applicable
        vATNO, // Replace with appropriate value if applicable
        bLNO, // Replace with appropriate value if applicable
        contNO, // Replace with appropriate value if applicable
        transactionClass, // Replace with appropriate value if applicable
        transactionType, // Replace with appropriate value if applicable
        incoiveRemark, // Replace with appropriate value if applicable
      } = props;

      // Validate all required fields
      await validateField(selectedAuthority, "Selected Authority");
      await validateField(creditNoteDate, "Credit Note Date");
      await validateField(creditNoteNumber, "Credit Note Number");
      await validateField(creditNoteAmount, "Credit Note Amount");

      // Check if any of the fields are not empty
      if (
        selectedAuthority !== "" &&
        creditNoteDate !== "" &&
        creditNoteNumber !== "" &&
        creditNoteAmount !== ""
      ) {
        // Prepare data to be sent to the backend
        const inputCreditNoteData = {
          invoice_header_id: null,
          user_id: null,
          customer_id: customerId,
          credit_note_number: null,
          amount: creditNoteAmount,
          approval_status: null,
          active_status: null,
          // created_by: authUserId,
          credit_note_authority_id: selectedAuthority,
          credit_note_date: creditNoteDate,
          remark: incoiveRemark,
          transaction_class_id: transactionClass,
          transaction_type_id: transactionType,
          invoice_type: selectedCurrType,
          currency_id: currCode,
          exchange_rate: eXGRate,
          payment_term_id: payterm,
          print_option: selectedPrintOption,
          customer_s_vat_number: sVATNO,
          customer_vat_number: vATNO,
          bill_number: bLNO,
          container_numbers: contNO,
        };

        // Send data to the backend for update/save
        const response = await axios.post(
          `${BASE_URL}/credit_note/store`,
          inputCreditNoteData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        if (response.status === 200) {
          // If the request is successful
          // Show success message
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // Optionally, perform additional actions after successful save
        }
      }
    } catch (error) {
      // If an error occurs during validation or request
      console.error("Error saving credit note:", error);
      // Show alert with error message
      alert("Error saving credit note. Please try again.");
    }
  };

  function setSelectedRow(rowid, row) {
    setRateCode(row.rate_id);
    setCurrCode(row.currency_id);
    setExgRaterRow(row.exchange_rate);
    setVolueRow(row.volume);
    setAmountRow(amountFormat(row.amount));
    setTotalAmount(row.TotalAmount);
    setRemark(row.remark ? row.remark : "");
    setSelectVATTax(row.vat_percentage);
    setSelectNBTTax(row.nbt_percentage);
    setSelectedTbaleRowID(rowid);
  }

  const headercolumns = [
    "Rate",
    "Currency",
    "EX Rate",
    "Volume",
    "Amount",
    "Total Amount (LKR)",
    "Remark",
    "NBT Percentage",
    "VAT Percentage",
    "NBT Amount",
    "VAT Amount",
  ];
  const columns = [
    "RateName",
    "Currency",
    "exchange_rate",
    "volume",
    "amount",
    "TotalAmount",
    "remark",
    "nbt_percentage",
    "vat_percentage",
    "NBTAmount",
    "VATAmount",
  ];

  const printInvoice = (e) => {
    if (selectedInvoice !== "") {
      const queryParams = {
        is_customer_copy: isCustomerCopy,
        is_svat: selectedPrintOption === "S" ? true : false,
        is_local: selectedCurrType === "L" ? true : false,
        is_foreign: selectedCurrType === "F" ? true : false,
        is_normal: selectedPrintOption === "N" ? true : false,
      };

      const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");

      const url = `${BASE_URL}${pageComponent}/print/${selectedInvoice}?${queryString}`;

      axios
        .get(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((res) => {
          // Create a blob URL from the response data
          const blob = new Blob([res.data], { type: "application/pdf" });
          const pdfUrl = window.URL.createObjectURL(blob);

          // Open the PDF URL in a new tab
          window.open(pdfUrl);

          // Release the object URL when done to free up resources
          window.URL.revokeObjectURL(pdfUrl);

          // toast.success("Downloading file", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        })
        .catch((error) => {
          console.error("Error fetching PDF:", error);
        });
    } else {
      toast.error("Please select an Invoice", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetch(`${BASE_URL}${pageComponent}/alldropdown`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPageData(data);
      })
      .catch((error) => {
        alert(error);
      });
  }, [authtoken, pageComponent]);

  const checkData = () => {
  };

  const tableHeaders = [
    "Items",
    "Order #",
    "Amount",
    "Status",
    "Delivery Driver",
  ];

  const getTransactionTypeValue = () => {
    if (vATNO && vATNO.length > 1) {
      return transactionTypeListOptions.find((option) => option.value === 1);
    }
    if (transactionType !== "") {
      return transactionTypeListOptions.find((option) => option.value === transactionType);
    }
    return '';
  };

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form className="mt-4 bottom-border pb-3">
            <Row>
              <Col>
                <Form.Group className="z">
                  <Form.Label>Invoice No </Form.Label>
                  <SelectDropdown
                    options={invoiceOptions}
                    onChange={selectInvoive}
                    disabled={createNewInvoice}
                    value={
                      invNo !== ""
                        ? invoiceOptions.find(
                          (option) => option.label === invNo
                        )
                        : null
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Booking No <span className="RQField">*</span>
                  </Form.Label>

                  {

                    bookingNumber !== "" ?
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        name="exgrate"
                        disabled="true"
                        value={bookingNumber ? bookingNumber : ''}
                        onChange={(e) => setEXGRate(e.target.value)}
                        className={
                          validateFeildItems.includes("Booking No")
                            ? "border-red2"
                            : ""
                        }
                      />

                      :

                      <SelectDropdown
                        options={bookingNoOptions}
                        onChange={passBookingNUM}
                        value={
                          bookindNo !== ""
                            ? bookingNoOptions.find(
                              (option) => option.value === bookindNo
                            )
                            : null
                        }
                        clName={
                          validateFeildItems.includes("Booking No")
                            ? "border-red2"
                            : ""
                        }
                      />
                  }

                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Date <span className="RQField">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    autoComplete="off"
                    name="invoice_date"
                    value={pageValuesData.invoice_date}
                    onChange={hadleInputValues}
                    className={
                      validateFeildItems.includes("Invoice Date")
                        ? "border-red2"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Consignee <span className="RQField">*</span>
                  </Form.Label>
                  <SelectDropdown
                    isMulti
                    options={customersoptions}
                    onChange={setConsignerDt}
                    value={Object.keys(customersoptions).length === 1 ? customersoptions[0] : customersoptions}
                    clName={
                      validateFeildItems.includes("Consignee") ? "border-red2" : ""
                    }
                  />

                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1 ">
                  <Form.Check
                    type="checkbox"
                    label="All Bookings"
                    onChange={handleYearBooking}
                    checked={isAllBooking}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1 ">
                  <Form.Check
                    type="checkbox"
                    label="Customer Copy"
                    onChange={handleCustomerCopy}
                    checked={isCustomerCopy}
                  />
                </Form.Group>
              </Col>
              <Col><div style={{ color: 'red', fontWeight: '600' }}> <spam>{approvedStatus === 1 ? "Invoice is Approved" : approvedStatus === 0 ? "Invoice is NOT Approved" : ""}</spam></div></Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>
                  Invoice Type Customer <span className="RQField">*</span>
                </Form.Label>
                <div
                  className={
                    validateFeildItems.includes("Invoice Type")
                      ? "border-red2"
                      : ""
                  }
                >
                  <input
                    type="radio"
                    value="L"
                    name="currtype"
                    onChange={handlecurrOption}
                    checked={selectedCurrType === "L"}
                  />{" "}
                  <Form.Label>Local</Form.Label>
                  <input
                    type="radio"
                    value="F"
                    className="ms-4"
                    name="currtype"
                    onChange={handlecurrOption}
                    checked={selectedCurrType === "F"}
                  />{" "}
                  <Form.Label>Foreign</Form.Label>
                </div>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Currency <span className="RQField">*</span>
                  </Form.Label>
                  <SelectDropdown
                    options={currencyOptions}
                    onChange={setCurrencyDt}
                    value={
                      currCode !== ""
                        ? currencyOptions.find(
                          (option) => option.value === currCode
                        )
                        : selectedCurrType === "L"
                          ? currencyOptions[0]
                          : currencyOptions[1]
                    }
                    clName={
                      validateFeildItems.includes("Currency")
                        ? "border-red2"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Exchange Rate <span className="RQField">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="exgrate"
                    value={eXGRate ? eXGRate : ''}
                    onChange={(e) => setEXGRate(e.target.value)}
                    className={
                      validateFeildItems.includes("Exg Rate")
                        ? "border-red2"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Payment Term <span className="RQField">*</span>
                  </Form.Label>
                  <SelectDropdown
                    options={paytermListOptions}
                    onChange={setPaytermDt}
                    value={
                      payterm !== ""
                        ? paytermListOptions.find(
                          (option) => option.value === payterm
                        )
                        : null
                    }
                    clName={
                      validateFeildItems.includes("Payment Term")
                        ? "border-red2"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>SVAT No</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="svatno"
                    value={sVATNO ? sVATNO : ''}
                    onChange={(e) => setsVATNO(e.target.value)}
                    className={
                      validateFeildItems.includes("S Vat No")
                        ? "border-red2"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>
                  Print Options - Customer <span className="RQField">*</span>
                </Form.Label>
                <div
                  className={
                    validateFeildItems.includes("Print Option")
                      ? "border-red2"
                      : ""
                  }
                >
                  <input
                    type="radio"
                    value="N"
                    name="printtype"
                    onChange={handlePrintOption}
                    checked={selectedPrintOption === "N"}
                  />{" "}
                  <Form.Label>Normal</Form.Label>
                  <input
                    type="radio"
                    value="S"
                    name="printtype"
                    className="ms-4"
                    onChange={handlePrintOption}
                    checked={selectedPrintOption === "S"}
                  />{" "}
                  <Form.Label>SVAT</Form.Label>
                </div>
              </Col>

              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>VAT No</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="vatno"
                    value={vATNO ? vATNO : ''}
                    onChange={(e) => setVATNO(e.target.value)}
                    className={
                      validateFeildItems.includes("Vat No") ? "border-red2" : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    BL Nos <span className="RQField">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    value={bLNO ? bLNO : ''}
                    onChange={(e) => setBLNO(e.target.value)}
                    className={
                      validateFeildItems.includes("BL Nos") ? "border-red2" : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Transaction Class <span className="RQField">*</span>
                  </Form.Label>
                  <SelectDropdown
                    options={transactionClassListOptions}
                    onChange={setTransactionClassDt}
                    value={
                      transactionClass !== ""
                        ? transactionClassListOptions.find(
                          (option) => option.value === transactionClass
                        )
                        : null
                    }
                    clName={
                      validateFeildItems.includes("Transaction Class")
                        ? "border-red2"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Transaction Type <span className="RQField">*</span>
                  </Form.Label>
                  <SelectDropdown
                    options={transactionTypeListOptions}
                    onChange={setTransactionTypeDt}
                    value={getTransactionTypeValue()}
                    clName={validateFeildItems.includes("Transaction Type") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Container Nos <span className="RQField"></span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="Hbl"
                    value={contNO ? contNO : ''}
                    onChange={(e) => setContNO(e.target.value)}
                    className={
                      validateFeildItems.includes("Container Nos")
                        ? "border-red2"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Remark <span className="RQField"></span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="Hbl"
                    value={incoiveRemark ? incoiveRemark : ''}
                    onChange={(e) => setINvoiceRemark(e.target.value)}
                    className={
                      validateFeildItems.includes("Remark")
                        ? "border-red2"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <div
              style={{
                border: "2px solid #FB8500",
                padding: "5px",
                borderRadius: "10px",
              }}
            >
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>
                      Rate <span className="RQField">*</span>
                    </Form.Label>
                    <SelectDropdown
                      options={ratesOptions}
                      onChange={selectRateChange}
                      value={
                        rateCode !== ""
                          ? ratesOptions.find(
                            (option) => option.value === rateCode
                          )
                          : null
                      }
                      clName={
                        tableValidateFeildItems.includes("rate")
                          ? "border-red2"
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {pageData ? (
                    pageData.taxes_data !== undefined ? (
                      pageData.taxes_data.map((taxItem) =>
                        taxItem.active_status === 1 ? (
                          <div key={taxItem.id}>
                            <Form.Group
                              className="mb-1"
                              controlId={taxItem.Tax}
                            >
                              <Form.Check
                                type="checkbox"
                                label={taxItem.tax}
                                id={taxItem.id}
                                onChange={setVAT}
                              />
                            </Form.Group>
                          </div>
                        ) : (
                          ""
                        )
                      )
                    ) : (
                      <div>No taxes data available</div>
                    )
                  ) : (
                    <div>Loading...</div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>
                      Currency <span className="RQField">*</span>
                    </Form.Label>
                    <SelectDropdown
                      options={currencyOptions}
                      onChange={setCurrencyDt}
                      value={
                        currCode !== ""
                          ? currencyOptions.find(
                            (option) => option.value === currCode
                          )
                          : null
                      }
                      clName={
                        tableValidateFeildItems.includes("Currency")
                          ? "border-red2"
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>
                      Exchange Rate <span className="RQField">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      name="exg_rate"
                      value={exgRaterRow}
                      onChange={(e) => setExgRaterRow(e.target.value)}
                      className={
                        tableValidateFeildItems.includes("Exg Rate")
                          ? "border-red2"
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>
                      Volume <span className="RQField">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      name="VolumeRow"
                      value={volueRow}
                      onChange={(e) => setVolueRow(e.target.value)}
                      className={
                        tableValidateFeildItems.includes("Volume")
                          ? "border-red2"
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>
                      Amount <span className="RQField">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      name=""
                      value={amountRow}
                      onChange={(e) => setAmountRow(e.target.value)}
                      className={
                        tableValidateFeildItems.includes("Amount")
                          ? "border-red2"
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs lg="9">
                  <Form.Group>
                    <Form.Label>
                      Remark <span className="RQField">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      name="Hbl"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      className={
                        tableValidateFeildItems.includes("Remark")
                          ? "border-red2"
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
                <Col className="text-center mt-4" xs lg="3">
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={setTableValue}
                  >
                    ADD
                  </Button>
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={RemoveSelectedDataRow}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </div>
            <Row className="mt-2">
              <BillTable
                data={tableItems}
                columns={columns}
                headercolumns={headercolumns}
                setSelectedRow={setSelectedRow}
              />
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Total USD Amount </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="totAmountUSD"
                    disabled={true}
                    value={currCode === 2 ? formatCurrency(parseInt(calculateDollerTotalAmount())) : ''}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Total Amount </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    disabled="true"
                    autoComplete="off"
                    name="TotalAmount"
                    value={formatLocalCurrency(calculateTotalAmount())}
                    onChange={(e) => setTotalAmount(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Contact</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="Contact"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="text-center mt-1">
        <Col className="text-end">
          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="new"
            label="New"
            onClick={hadleNewInvoice}
            className="ms-3"
            variant="primary"
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="save"
            label="Edit"
            onClick={saveINvoice}
            className="ms-3"
            variant="primary"
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="save"
            label="Save"
            onClick={saveINvoice}
            className="ms-3"
            variant="primary"
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            label="Credit Note"
            onClick={saveCreditNote}
            className="ms-3"
            variant="primary"
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="print"
            label="Print"
            onClick={printInvoice}
            className="ms-3"
            variant="primary"
          />

          <Button
            className="ms-3"
            variant="primary"
            type="submit"
            onClick={checkData}
          >
            Cancel
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {/* <TableView data={data} columns={columns} setSelectedRow={setSelectedRow} /> */}
        </Col>
      </Row>
    </Container>
  );
};

export default Invoice;
