import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import axios from "axios";
import Select from 'react-select';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import SelectDropdown from "../SelectDropdown";
import { ToastContainer, toast } from "react-toastify";
import TableView from "../TableView";
import ReusableButtonComponent from "../CommonComponents/ReusableButtonComponent";

const UserCreation = (props) => {
    const storedToken = Cookies.get("authToken");
    const storedUser = Cookies.get("authUser");
    const [authtoken, setAuthToken] = useState(storedToken || "");
    const [authUser, setAuthUser] = useState(storedUser || "");
    const [pageComponent, setPageComponent] = useState("users");
    const [usersDataList, setUsersDataList] = useState(undefined);
    const [pageValuesData, setpageValuesData] = useState({ user_code: "", name: "", email: "", password: "" });
    const [newUserMode, setNewUserMode] = useState(false);
    const [cantBeEmptyFeilds, setCantBeEmptyFeilds] = useState(["user_code", "name", "email", "password"]);
    const [validateFailedInputsList, setValidateFailedInputsList] = useState([]);
    const [feildValidate, setFeildValidate] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;



    useEffect(() => {
        fetch(`${BASE_URL}${pageComponent}/all`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setUsersDataList(data.users)
            })
            .catch((error) => {
                console.error(error);
            });
    }, [authtoken, pageComponent]);

    const usersOptions = usersDataList ?
        usersDataList.map((userOptionItem) => ({
            value: userOptionItem.id,
            label: userOptionItem.user_code,
        })) : [];

    const newUserhadle = () => {
        setpageValuesData({ user_code: "", name: "", email: "", password: "" });
        setNewUserMode(true);
    }

    const tTitle = [
        "User ID",
        "User Name",
        "Active",
    ];
    const columns = [
        "user_code",
        "name",
        "active_status",
    ];

    function setSelectedRow(rowid) {
        setNewUserMode(false);
        setpageValuesData(usersDataList.find(Item => Item.id === rowid));
        if (pageValuesData.active_status !== undefined && pageValuesData.active_status === 1) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }

    const pageValueHadle = (e) => {
        const { name, value } = e.target;
        setpageValuesData({ ...pageValuesData, [name]: value });
    };

    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            setIsChecked(true);
            setpageValuesData({ ...pageValuesData, active_status: 1 });
        } else {
            setIsChecked(false);
            setpageValuesData({ ...pageValuesData, active_status: 0 });
        }

    };

    const dropdownValueHandle = (value, key_Name) => {
        setpageValuesData((prevState) => ({
            ...prevState,
            [key_Name.name]: value.value,
        }));
    };

    const validateEmptyField = (fieldValue, fieldName) => {
        if (typeof fieldValue === 'string' && !fieldValue.trim()) {
            setFeildValidate(false);
            toast.error(`${fieldName} can not be empty`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        } else {
            setFeildValidate(true);
        }
        return true;
    };

    const handleSave = async (event) => {
        event.preventDefault();
        const isEmpty = (value) => value === null || value === undefined || value === " " || !value;
        const newvalidateFailedInputsList = [];
        for (const key in pageValuesData) {
            const value = pageValuesData[key];
            if (isEmpty(value) && cantBeEmptyFeilds.includes(key)) {
                newvalidateFailedInputsList.push(key);
                const keyWord = key.split('_');
                const capitalizeKeyWords = keyWord.map(word => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                });
                const labelText = capitalizeKeyWords.join(' ');
                validateEmptyField(value, labelText);
            }
        }

        setValidateFailedInputsList(newvalidateFailedInputsList);
        if (newvalidateFailedInputsList.length === 0) {
            setFeildValidate(true);
            datapassStage();
        } else {
            console.log("Canot be Empty felids" + newvalidateFailedInputsList.length);
        }
    }

    const datapassStage = () => {
        if (feildValidate === true) {
            if (emailPattern.test(pageValuesData.email)) {
                if (pageValuesData.password !== pageValuesData.passwordconform) {
                    toast.error("Password & Conform Password Not Equal", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    if (pageValuesData.id !== undefined) {
                        axios
                            .post(
                                `${BASE_URL}${pageComponent}/update/${pageValuesData.id}`,
                                pageValuesData,
                                {
                                    method: "POST",
                                    headers: {
                                        Authorization: `Bearer ${authtoken}`,
                                    },
                                }
                            )
                            .then((response) => {
                                toast.success("Successfully Updated Data ! ", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });

                                setTimeout(window.location.reload.bind(window.location), 2000);
                            })
                            .catch((error) => {
                                toast.error("Something Went Wrong", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            });
                    } else {
                        axios
                            .post(`${BASE_URL}${pageComponent}/store`, pageValuesData, {
                                method: "POST",
                                headers: {
                                    Authorization: `Bearer ${authtoken}`,
                                },
                            })
                            .then((response) => {
                                toast.success("Successfully Save Data ! ", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                setTimeout(window.location.reload.bind(window.location), 2000);
                            })
                            .catch((error) => {
                                console.error(error.response.data.message);
                                toast.error("Something Went Wrong", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            });
                    }

                }
            } else {
                toast.error("Invalid Email ", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    const deleteRow = async (event) => {
        // event.preventDefault();
        // try {
        //     await axios.delete(
        //         `${BASE_URL}${pageComponent}/destroy/${pageValuesData.id}`, // Correct URL format
        //         {
        //             headers: {
        //                 Authorization: `Bearer ${authtoken}`,
        //             },
        //         }
        //     );

        //     toast.warning("Successfully Deleted Data Row", {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });

        //     setTimeout(() => {
        //         window.location.reload(); // Reload the page after deletion
        //     }, 2000);
        // } catch (error) {
        //     console.error("Error deleting row:", error);
        //     // Handle error and display a message if needed
        // }
    };

    return (
        <Container fluid>
            <ToastContainer autoClose={2000} />
            <Row>
                <Col>
                    <Form className="mt-3 bottom-border pb-3">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label>User ID </Form.Label>
                                    <SelectDropdown
                                        style={{ display: newUserMode ? 'none !important' : 'block' }}
                                        options={usersOptions}
                                        name="user_code"
                                        onChange={dropdownValueHandle}
                                        value={pageValuesData !== undefined ? usersOptions.find(option => option.label === pageValuesData.user_code) : null}
                                        disabled={newUserMode === true ? true : false}
                                        className={validateFailedInputsList.includes("user_code") ? "border-red2" : ""}

                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="user_code"
                                        value={pageValuesData !== undefined ? pageValuesData.user_code : ""}
                                        onChange={pageValueHadle}
                                        disabled={!newUserMode}
                                        className={validateFailedInputsList.includes("user_code") ? "border-red2" : ""}
                                        style={{ display: newUserMode ? 'block' : 'none' }}
                                    />

                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label>E-Mail</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="email"
                                        value={pageValuesData !== undefined ? pageValuesData.email : ""}
                                        // readOnly={selectRowData !== "" ? true : false}
                                        className={validateFailedInputsList.includes("email") ? "border-red2" : ""}
                                        onChange={pageValueHadle}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="name"
                                        value={pageValuesData !== undefined ? pageValuesData.name : ""}
                                        onChange={pageValueHadle}
                                        // readOnly={selectRowData !== "" ? true : false}
                                        className={validateFailedInputsList.includes("name") ? "border-red2" : ""}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="password"
                                        onChange={pageValueHadle}
                                        // value={protCode}
                                        // readOnly={selectRowData !== "" ? true : false}
                                        className={validateFailedInputsList.includes("password") ? "border-red2" : ""}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="passwordconform"
                                        onChange={pageValueHadle}
                                        className={validateFailedInputsList.includes("password") ? "border-red2" : ""}
                                    />
                                </Form.Group>
                            </Col>
                            <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    label="Active"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="text-center">
                            <Col className="text-end">
                                {/* <Button
                                    className="ms-3"
                                    variant="primary"
                                    onClick={checkdata}
                                >
                                    Check Data
                                </Button> */}
                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="save"
                                    label="Save"
                                    onClick={handleSave}
                                    disabled={newUserMode === true ? false : true}
                                    className="ms-3"
                                    variant="primary"
                                />

                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="new"
                                    label="New"
                                    onClick={newUserhadle}
                                    className="ms-3"
                                    variant="primary"
                                />

                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="update"
                                    label="Update"
                                    onClick={handleSave}
                                    disabled={newUserMode === true ? true : false}
                                    className="ms-3"
                                    variant="primary"
                                />

                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="delete"
                                    label="Delete"
                                    onClick={deleteRow}
                                    disabled={newUserMode === true ? true : false}
                                    className="ms-3"
                                    variant="primary"
                                />
                                {/* <Button className="ms-3" variant="primary" type="submit">
                                    Cancel
                                </Button> */}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <TableView
                        data={usersDataList}
                        columns={columns}
                        tTitle={tTitle}
                        setSelectedRow={setSelectedRow}
                    />
                </Col>
            </Row>
        </Container>
    );
}
export default UserCreation;