import React, { useState, useEffect, Component, useRef } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import axios from "axios";
import Select from 'react-select';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import TableView from "../TableView";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Voyage = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("voyages");
  const [data, setData] = useState(undefined);
  const [vesselId, setVesselId] = useState("");
  const [vesselCode, setVesselCode] = useState("");
  const [vesselList, setVesselList] = useState(undefined);
  const [vesselDescVal, setVesselDescVal] = useState("");
  const [voyageCode, setVoyageCode] = useState("");
  const [voyageDesc, setVoyageDesc] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [depatureDate, setDepatureDate] = useState("");
  const [oldVoylCode, setOldVoylCode] = useState("");
  const [btnActive, setBtnActive] = useState(true);
  const [selectRowData, setSelectRowData] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [emptyVesCode, setEmptyVesCode] = useState(false);
  const [emptyVoyage, setEmptyVoyage] = useState(false);
  const [emptyVoyageDesc, setEmptyVoyageDesc] = useState(false);
  const [emptyArrDate, setEmptyArrDate] = useState(false);
  const [emptyDepatureDate, setEmptyDepatureDate] = useState(false);
  const [emptyInput, setEmptyInput] = useState(false);

  const options = vesselList
    ? vesselList.map((vesselItem) => ({
      value: vesselItem.id,
      label: vesselItem.vessel_description,
    }))
    : [];


  const tTitle = [
    "Vessel Code",
    "Vessel Description",
    "Voyage Code",
    "Voyage Description",
    "Voy. Arrival Date",
    "Voy. Departure Date",
  ];

  const columns = [
    "vessel_code",
    "vessel_description",
    "voyage_code",
    "voyage_description",
    "voyage_arrival_date",
    "voyage_departure_date",
  ];

  const handleButtonClickPDF = () => {
    if (!Array.isArray(data) || data.length === 0 || !Array.isArray(columns) || columns.length === 0) {
      // Handle the scenario where the data or selected keys are invalid or empty
      toast.error("Invalid data or selected keys for export.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const filteredRows = data.map(obj => {
      const filteredRow = {};
      columns.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          filteredRow[key] = obj[key];
        }
      });
      return filteredRow;
    });

    const doc = new jsPDF();
    const rows = filteredRows.map(obj => Object.values(obj));

    const columnStyles = {}; // Object to hold column styles

    // Set cell widths (adjust widths as needed)
    columns.forEach(key => {
      columnStyles[key] = { cellWidth: 30 }; // Change width as per your requirement
    });

    doc.autoTable({
      columns: tTitle.map(key => ({ header: key, dataKey: key })),
      body: rows,
      columnStyles,
      startY: 5, // Adjust the starting Y position for the table
      // ... other configuration options
    });

    // Save the PDF file
    const dateTime = new Date().toISOString().replace(/[-T:.Z]/g, '');
    const fileName = `Voyage_PDF_Report_${dateTime}.pdf`;
    doc.save(fileName);
  };

  const inputRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function dateSetting(date) {
    let selected_Date = date;
    date !== null
      ? setStartDate(selected_Date) ||
      setArrivalDate(date.toLocaleDateString("sv-SE")) ||
      setEmptyArrDate(false)
      : toast.error("Arrival Date can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
  }

  function endDateSet(date) {
    date !== null
      ? setEndDate(date) ||
      setDepatureDate(date.toLocaleDateString("sv-SE")) ||
      setEmptyDepatureDate(false)
      : toast.error("Depature Date can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
  }

  const createNewRow = () => {
    setBtnActive(false);
    setSelectRowData("");
    setVoyageCode("");
    setVoyageDesc("");
  };

  useEffect(() => {
    fetch(`${BASE_URL}vessels/dropdown`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setVesselList(data.vessels);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [authtoken]);

  const handleVesselChange = (selectedOption) => {
    setVesselId(selectedOption.value);
    setVesselCode(selectedOption.value);
    setVesselDescVal(selectedOption.label);
  };

  function setSelectedRow(rowid) {
    const currunt_ID = rowid;
    axios
      .get(`${BASE_URL}${pageComponent}/single/${rowid}`, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        const selectRowData = res.data.voyage[0];
        setSelectRowData(selectRowData);
        setVesselId(selectRowData.vessel_id1 ? selectRowData.vessel_id1 : "");
        setVesselCode(
          selectRowData.vessel_code ? selectRowData.vessel_code : ""
        );
        setVesselDescVal(
          selectRowData.vessel_description
            ? selectRowData.vessel_description
            : ""
        );
        setVoyageCode(
          selectRowData.voyage_code ? selectRowData.voyage_code : ""
        );
        setOldVoylCode(
          selectRowData.voyage_code ? selectRowData.voyage_code : ""
        );
        setVoyageDesc(
          selectRowData.voyage_description
            ? selectRowData.voyage_description
            : ""
        );
        let aRRDate = selectRowData.voyage_arrival_date
          ? selectRowData.voyage_arrival_date
          : "";
        setStartDate(new Date(aRRDate));
        setArrivalDate(
          selectRowData.voyage_arrival_date
            ? selectRowData.voyage_arrival_date
            : ""
        );
        let dPTDate = selectRowData.voyage_departure_date
          ? selectRowData.voyage_departure_date
          : "";
        setEndDate(new Date(dPTDate));
        setDepatureDate(
          selectRowData.voyage_departure_date
            ? selectRowData.voyage_departure_date
            : ""
        );
        setBtnActive(false);
      });
  }

  const handleSave = (event) => {
    event.preventDefault();
    setEmptyInput(false);

    if (vesselCode.length === 0) {
      toast.error("Vessel Code can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmptyVesCode(true);
      setEmptyInput(true);
      return;
    } else {
      setEmptyVesCode(false);
    }

    if (voyageCode.length === 0) {
      toast.error("Voyage Code can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmptyVoyage(true);
      setEmptyInput(true);
    } else {
      setEmptyVoyage(false);
    }

    if (voyageDesc.length === 0) {
      toast.error("Voyage Description can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmptyVoyageDesc(true);
      setEmptyInput(true);
    } else {
      setEmptyVoyageDesc(false);
    }

    if (startDate == null) {
      toast.error("Arrival Date can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmptyArrDate(true);
      setEmptyInput(true);
    } else {
      setEmptyArrDate(false);
      setEmptyInput(false);
    }

    if (endDate.length === 0) {
      toast.error("Depature Date can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmptyDepatureDate(true);
      setEmptyInput(true);
    } else {
      setEmptyDepatureDate(false);
      setEmptyInput(false);
    }

    if (emptyInput !== true) {
      const inputData = {
        vessel_id: vesselId,
        voyage_code: voyageCode,
        voyage_description: voyageDesc,
        voyage_arrival_date: arrivalDate,
        voyage_departure_date: depatureDate,
      };
      const duplicate = data.some((el) => el.VoyCode === voyageCode);

      duplicate === true
        ? toast.error("This Voyage code already included", {
          position: toast.POSITION.TOP_RIGHT,
        })
        : axios
          .post(`${BASE_URL}${pageComponent}/store`, inputData, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          })
          .then((response) => {
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(window.location.reload.bind(window.location), 2000);
          })
          .catch((error) => {
            toast.error("error", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
    }
  };

  useEffect(() => {
    fetch(`${BASE_URL}${pageComponent}/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data.voyages);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [authtoken, pageComponent]);

  const edditRow = (event) => {
    event.preventDefault();

    if (selectRowData !== "") {
      setEmptyInput(false);

      if (vesselCode.length === 0) {
        toast.error("Vessel Code can not be empty", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEmptyVesCode(true);
        setEmptyInput(true);
        return;
      } else {
        setEmptyVesCode(false);
      }

      if (voyageCode.length === 0) {
        toast.error("Voyage Code can not be empty", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEmptyVoyage(true);
        setEmptyInput(true);
      } else {
        setEmptyVoyage(false);
      }

      if (voyageDesc.length === 0) {
        toast.error("Voyage Description can not be empty", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEmptyVoyageDesc(true);
        setEmptyInput(true);
      } else {
        setEmptyVoyageDesc(false);
      }

      if (startDate == null) {
        toast.error("Arrival Date can not be empty", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEmptyArrDate(true);
        setEmptyInput(true);
      } else {
        setEmptyArrDate(false);
        setEmptyInput(false);
      }

      if (endDate.length === 0) {
        toast.error("Depature Date can not be empty", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEmptyDepatureDate(true);
        setEmptyInput(true);
      } else {
        setEmptyDepatureDate(false);
        setEmptyInput(false);
      }

      if (emptyInput !== true) {
        const inputData = {
          vessel_id: vesselId,
          voyage_code: voyageCode,
          voyage_description: voyageDesc,
          voyage_arrival_date: arrivalDate,
          voyage_departure_date: depatureDate,
        };
        axios
          .post(
            `${BASE_URL}${pageComponent}/update/${selectRowData.id}`,
            inputData,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          )
          .then((response) => {
            window.location.reload(false);
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        toast.error("Please Select data Row", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const deleteRow = (event) => {
    event.preventDefault();
    axios
      .delete(`${BASE_URL}${pageComponent}/destroy/${selectRowData.id}`, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then(() => {
        toast.warning("Successfully Deleted Data Row", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch((error) => {
        toast.error("Error deleting row:", error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row style={{ position: 'relative' }}>
        <Col>
          <Form className="mt-3 bottom-border pb-3">
            <Row>
              <Col className="">
                <Form.Group className="mb-3 select-container " controlId="Vessel">
                  <Form.Label>Vessel </Form.Label>
                  <Select
                    options={options}
                    onChange={(e) => handleVesselChange(e)}
                    value={
                      vesselCode !== ""
                        ? { value: vesselCode, label: vesselDescVal }
                        : null
                    }
                    menuPortalTarget={document.body} // Render the menu outside of the normal parent hierarchy
                    menuPosition="fixed" // Ensure the menu stays in a fixed position
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 " controlId="Voyage_code">
                  <Form.Label>Voyage Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Voy. Code"
                    readOnly={selectRowData !== "" ? true : false}
                    name="VesselCode"
                    className={emptyVoyage === true ? "border-red2" : ""}
                    value={voyageCode}
                    onChange={(e) => setVoyageCode(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="Voyage_des">
                  <Form.Label>Voyage Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Voy. Desc"
                    name="VesselDesc"
                    className={emptyVoyageDesc === true ? "border-red2" : ""}
                    value={voyageDesc}
                    onChange={(e) => setVoyageDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="Arrival_date">
                  <Form.Label>Arrival Date </Form.Label>
                  <DatePicker
                    selectsStart
                    selected={startDate}
                    onChange={(Date) => dateSetting(Date)}
                    startDate={startDate}
                    className={emptyArrDate === true ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="Depature_Date"
                  name="depatureDate"
                >
                  <Form.Label>Departure Date</Form.Label>
                  <DatePicker
                    selectsEnd
                    selected={endDate}
                    onChange={(date) => endDateSet(date)}
                    endDate={endDate}
                    startDate={startDate}
                    minDate={startDate}
                    className={emptyDepatureDate === true ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="text-center">
              <Col className="text-end">

                {props.selectedAuthority && props.selectedAuthority.new === 1 ? (
                  <>
                    <Button
                      className="ms-3"
                      variant="primary"
                      onClick={selectRowData !== "" ? edditRow : handleSave}
                      disabled={btnActive}
                    >
                      Save
                    </Button>

                    <Button
                      className="ms-3"
                      variant="primary"
                      onClick={createNewRow}
                    >
                      New
                    </Button>
                  </>

                ) : null}

                {props.selectedAuthority && props.selectedAuthority.edit === 1 ? (
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={edditRow}
                    disabled={btnActive}
                  >
                    Update
                  </Button>
                ) : null}

                {props.selectedAuthority && props.selectedAuthority.delete === 1 ? (
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={deleteRow}
                    disabled={btnActive}
                  >
                    Delete
                  </Button>
                ) : null}

                {props.selectedAuthority && props.selectedAuthority.print === 1 ? (
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={handleButtonClickPDF}
                  >
                    Print
                  </Button>
                ) : null}

                {/* <Button className="ms-3" variant="primary" type="submit">
                  Cancel
                </Button> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TableView
            data={data}
            columns={columns}
            tTitle={tTitle}
            setSelectedRow={setSelectedRow}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Voyage;
