import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import BASE_URL from "./config";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import companyLogo from "../images/logo.png"
import Register from "./Register";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${BASE_URL}auth/login?email=${email}&password=${password}`,
        {
          method: "POST",
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const authToken = responseData.access_token;
        // Set session expiry time (e.g., 30 minutes from now)
        const sessionExpiry = new Date();
        sessionExpiry.setMinutes(sessionExpiry.getMinutes() + 30);
        Cookies.set("authToken", authToken);
        Cookies.set("authToken", responseData.access_token);
        Cookies.set("authUser", JSON.stringify(responseData.user));
        localStorage.setItem('userAuthorities', JSON.stringify(responseData.userAuthority));

        Cookies.set("sessionExpiry", sessionExpiry.toISOString()); // Store expiry time
        Cookies.set("isLoggedIn", "true");
        window.location.href = "/dashboard";
      } else {
        toast.error("Incorrect User Name Or Password", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <Container fluid className="login-page-container">
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <div className="Auth-form-container">
            <form className="Auth-form" onSubmit={handleSubmit}>
              <div className="Auth-form-content mb-3">
                <div className="text-center">
                  <img className="ms-auto me-auto mb-2" src={companyLogo} alt="LOGO" loading="lazy" style={{ maxWidth: '100%' }} />
                </div>
                <div className="form-group mt-3">
                  <label>User Name </label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Password </label>
                  <input
                    type="password"
                    className="form-control mt-1"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="d-grid gap-2 mt-3">
                  <button type="submit" className="btn btn-primary text-center">
                    Submit
                  </button>
                </div>
                <div className="mt-3">
                  {/* <Register/> */}
                </div>
              </div>

            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
