import React from "react";

const Footer = () => {
  return (
    <footer className="py-3 ">
      {/* <ul class="nav justify-content-center border-bottom pb-3 mb-3">
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Home</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Features</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Pricing</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">FAQs</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">About</a></li>
    </ul> */}
      <p className="text-center text-muted mt-2 footer-text">
        © 2024 Evolve Technologies
      </p>
    </footer>
  );
};

export default Footer;
