import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectDropdown from "../SelectDropdown";
import ReusableButtonComponent from "../CommonComponents/ReusableButtonComponent";
import TableView from "../TableView";
import axios from "axios";
import Cookies from "js-cookie";
import BASE_URL from "../config";

const AsycudaXml = (props) => {
    const storedToken = Cookies.get("authToken");

    const [formData, setFormData] = useState({
        officeCode: "",
        referenceCode: "",
        vessel: "",
        voyage: "",
        dateOfDeparture: "",
        voyageNo: "",
        isCarCarrier: false,
        houseBLNo: "",
        consolidationCargo: "",
    });

    const [blNumbers, setBlNumbers] = useState([]);
    const [vesselOptions, setVesselOptions] = useState([]);
    const [voyageOptions, setVoyageOptions] = useState([]);

    const columns = ["BL_Number", "Consolidate_Cargo"];
    const tTitle = ["BL Number", "Consolidate Cargo"];

    const handleInputChange = (name, option) => {
        if (option && option.value && option.label) {
            setFormData({
                ...formData,
                [name]: { id: option.id, value: option.value, label: option.label }
            });
        } else {
            setFormData({ ...formData, [name]: option });
        }
    };

    const handleAddBLNumber = () => {
        const { houseBLNo, consolidationCargo } = formData;
        if (houseBLNo) {
            setBlNumbers([
                ...blNumbers,
                { bl: houseBLNo, cargo: consolidationCargo },
            ]);
            setFormData({ ...formData, houseBLNo: "" });
        } else {
            toast.error("House BL No is required.");
        }
    };

    const handleSave = async () => {
        const transformedData = {
            officeCode: formData.officeCode ? formData.officeCode.label : "",
            referenceCode: formData.referenceCode || "",
            vessel: formData.vessel ? formData.vessel.value : "",
            voyage: formData.voyage ? formData.voyage.value : "",
            voyageId: formData.voyage ? formData.voyage.id : "",
            dateOfDeparture: formData.dateOfDeparture || "",
            voyageNo: formData.voyageNo || "",
            isCarCarrier: formData.isCarCarrier,
            houseBLNo: formData.houseBLNo || "",
            consolidationCargo: formData.consolidationCargo ? formData.consolidationCargo.value : "",
        };

        try {
            const response = await axios.post(`${BASE_URL}generate_xml/store`, transformedData, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
                },
            });
            toast.success("Data saved successfully!");
            handleCancel();
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Failed to save data.");
        }
    };

    const handleCancel = () => {
        setFormData({
            officeCode: "",
            referenceCode: "",
            vessel: null,
            voyage: null,
            dateOfDeparture: "",
            voyageNo: "",
            isCarCarrier: false,
            houseBLNo: "",
            consolidationCargo: "",
        });
        setBlNumbers([]);
    };

    useEffect(() => {
        const fetchAllDropdownData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}generate_xml/alldropdown`, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });

                const vesselData = response.data.Vessels.map((vessel) => ({
                    value: vessel.vessel_description,
                    label: vessel.vessel_description,
                }));

                const voyageData = response.data.Voyages.map((voyage) => ({
                    id: voyage.voyage_code,
                    value: voyage.voyage_description,
                    label: voyage.voyage_description,
                }));

                const blData = response.data.BLNumbers.map((bl) => ({
                    value: bl.bill_number,
                    label: bl.bill_number,
                }));

                setVesselOptions(vesselData);
                setVoyageOptions(voyageData);
                setBlNumbers(blData);

            } catch (error) {
                console.error("Error fetching dropdown data:", error);
                toast.error("Failed to load dropdown options.");
            }
        };

        fetchAllDropdownData();
    }, [storedToken]);

    const handleGenerateXML = async () => {
        const transformedData = {
            officeCode: formData.officeCode ? formData.officeCode.label : "",
            referenceCode: formData.referenceCode || "",
            vessel: formData.vessel ? formData.vessel.value : "",
            voyage: formData.voyage ? formData.voyage.value : "",
            voyageId: formData.voyage ? formData.voyage.id : "",
            dateOfDeparture: formData.dateOfDeparture || "",
            voyageNo: formData.voyageNo || "",
            isCarCarrier: formData.isCarCarrier,
            houseBLNo: formData.houseBLNo ? formData.houseBLNo.value : "",
            consolidationCargo: formData.consolidationCargo ? formData.consolidationCargo.value : "",
        };

        try {
            const response = await fetch(`${BASE_URL}generate_xml/download`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(transformedData),
            });

            if (!response.ok) {
                throw new Error('Failed to generate XML file');
            }

            // Convert response to Blob to download as a file
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'BOL_Document.xml'); // The filename to save as
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error while generating XML:', error);
        }
    };


    return (
        <Container fluid style={{ boxShadow: "none", padding: "0" }}>
            <ToastContainer autoClose={2000} />
            <Row>
                <Col>
                    <h4 align="center">Generate XML</h4>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>
                                        Office Code <span className="RQField">*</span>
                                    </Form.Label>
                                    <SelectDropdown
                                        options={[
                                            { value: "1", label: "SECMB" },
                                            { value: "2", label: "ARKTM" },
                                        ]}
                                        name="officeCode"
                                        onChange={(option) => handleInputChange("officeCode", option)}
                                        value={formData.officeCode}
                                    />

                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>
                                        Reference Code <span className="RQField">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="referenceCode"
                                        value={formData.referenceCode}
                                        onChange={(e) =>
                                            handleInputChange("referenceCode", e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>
                                        Vessel <span className="RQField">*</span>
                                    </Form.Label>
                                    <SelectDropdown
                                        options={vesselOptions}
                                        name="vessel"
                                        onChange={(option) =>
                                            handleInputChange("vessel", option)
                                        }
                                        value={formData.vessel ? { value: formData.vessel.value, label: formData.vessel.label } : null}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>
                                        Voyage <span className="RQField">*</span>
                                    </Form.Label>
                                    <SelectDropdown
                                        options={voyageOptions}
                                        name="voyage"
                                        onChange={(option) =>
                                            handleInputChange("voyage", option)
                                        }
                                        value={formData.voyage ? { value: formData.voyage.value, label: formData.voyage.label } : null}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>
                                        Date of Departure <span className="RQField">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dateOfDeparture"
                                        value={formData.dateOfDeparture}
                                        onChange={(e) =>
                                            handleInputChange("dateOfDeparture", e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={8}>
                                <Form.Group className="mb-1">
                                    <Form.Label>
                                        Voyage No for XML <span className="RQField">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="voyageNo"
                                        value={formData.voyageNo}
                                        onChange={(e) =>
                                            handleInputChange("voyageNo", e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4} className="d-flex align-items-center">
                                <Form.Group className="mb-1">
                                    <Form.Check
                                        type="checkbox"
                                        label="Car Carrier"
                                        name="isCarCarrier"
                                        checked={formData.isCarCarrier}
                                        onChange={(e) =>
                                            handleInputChange("isCarCarrier", e.target.checked)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>
                                        House BL No <span className="RQField">*</span>
                                    </Form.Label>
                                    <SelectDropdown
                                        options={blNumbers}
                                        name="houseBLNo"
                                        onChange={(option) => handleInputChange("houseBLNo", option)}
                                        value={formData.houseBLNo ? { value: formData.houseBLNo.value, label: formData.houseBLNo.label } : null}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>
                                        Consolidation Cargo <span className="RQField">*</span>
                                    </Form.Label>
                                    <SelectDropdown
                                        options={[
                                            { value: 'Single Cargo', label: 'Single Cargo' },
                                            { value: 'Multiple Cargo', label: 'Multiple Cargo' },
                                        ]}
                                        name="consolidationCargo"
                                        onChange={(option) =>
                                            handleInputChange("consolidationCargo", option)
                                        }
                                        value={formData.consolidationCargo ? { value: formData.consolidationCargo.value, label: formData.consolidationCargo.label } : null}

                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="add"
                                    label="Add"
                                    className="mt-2"
                                    variant="secondary"
                                    onClick={handleAddBLNumber}
                                />
                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="delete"
                                    label="Delete"
                                    className="mt-2"
                                    variant="secondary"
                                />
                            </Col>
                        </Row>
                    </Form>
                    <Row className="mt-4">
                        <Col>
                            <TableView
                                data={blNumbers} // Use blNumbers for display
                                columns={columns}
                                tTitle={tTitle}
                                setSelectedRow={(rowid) => console.log(rowid)}
                            />
                        </Col>
                        <Row>
                            <Col className="text-end">
                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="save"
                                    label="Save"
                                    className="ms-3"
                                    variant="primary"
                                    onClick={handleSave}
                                />
                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="generate"
                                    label="Generate XML"
                                    className="ms-3"
                                    variant="primary"
                                    onClick={handleGenerateXML}
                                />
                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="cancel"
                                    label="Cancel"
                                    className="ms-3"
                                    variant="primary"
                                    onClick={handleCancel}
                                />
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default AsycudaXml;
