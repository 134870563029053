import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import TableView from '../TableView';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReusableButtonComponent from '../CommonComponents/ReusableButtonComponent';

const PaymentMode = (props) => {
    const data = [
        { Code: '001', Payment_Mode_Name: 'CHEQUE' },
        { Code: '002', Payment_Mode_Name: 'CASH' },
    ]
    const tTitle = ["Code", "Payment Mode Name"];
    const columns = ['Code', 'Payment_Mode_Name',];

    function setSelectedRow(rowid) {
        // axios
        //   .get(`${BASE_URL}${pageComponent}/single/${rowid}`, {
        //     headers: {
        //       Authorization: `Bearer ${authtoken}`,
        //     },
        //   })
        //   .then((res) => {
        //     const selectRowData = res.data;
        //     setSelectRowData(selectRowData);
        //     setCode(
        //       selectRowData.transaction_type.transaction_type_code
        //         ? selectRowData.transaction_type.transaction_type_code
        //         : ""
        //     );
        //     setTransactionType(
        //       selectRowData.transaction_type.transaction_type
        //         ? selectRowData.transaction_type.transaction_type
        //         : ""
        //     );
        //     setBtnActive(false);
        //   });
    }

    const handleButtonClickPDF = () => {
        if (!Array.isArray(data) || data.length === 0 || !Array.isArray(columns) || columns.length === 0) {
            // Handle the scenario where the data or selected keys are invalid or empty
            toast.error("Invalid data or selected keys for export.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const filteredRows = data.map(obj => {
            const filteredRow = {};
            columns.forEach(key => {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    filteredRow[key] = obj[key];
                }
            });
            return filteredRow;
        });

        const doc = new jsPDF();
        const rows = filteredRows.map(obj => Object.values(obj));

        const columnStyles = {}; // Object to hold column styles

        // Set cell widths (adjust widths as needed)
        columns.forEach(key => {
            columnStyles[key] = { cellWidth: 30 }; // Change width as per your requirement
        });

        doc.autoTable({
            columns: tTitle.map(key => ({ header: key, dataKey: key })),
            body: rows,
            columnStyles,
            startY: 5, // Adjust the starting Y position for the table
            // ... other configuration options
        });

        // Save the PDF file
        const dateTime = new Date().toISOString().replace(/[-T:.Z]/g, '');
        const fileName = `Payment_Mode_PDF_Report_${dateTime}.pdf`;
        doc.save(fileName);
    };

    return (
        <Container fluid>
            <ToastContainer autoClose={2000} />
            <Row>
                <Col>
                    <Form className='mt-3 bottom-border pb-3'>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control type="text" placeholder="001" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Payment Mode</Form.Label>
                                    <Form.Control type="text" placeholder="Invoice" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='text-center'>
                            <Col className='text-end'>
                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="new"
                                    label="New"
                                // onClick={handleNew}
                                />

                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="edit"
                                    label="Edit"
                                // onClick={handleEdit}
                                />

                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="save"
                                    label="Save"
                                // onClick={handleSave}
                                />

                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="delete"
                                    label="Delete"
                                // onClick={handleDelete}
                                />

                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="print"
                                    label="Print"
                                    onClick={handleButtonClickPDF}
                                />

                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <TableView data={data}
                        columns={columns}
                        tTitle={tTitle}
                        setSelectedRow={setSelectedRow} />
                </Col>
            </Row>
        </Container>
    );
}

export default PaymentMode;