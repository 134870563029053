import React, { useState } from "react";
import SelectDropdown from "../../SelectDropdown";
import { Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import BillTable from "../../BillTableView";
import { ToastContainer, toast } from "react-toastify";

const CustomerandSupplierinfo = (props) => {
  const [selectedTbaleRowID, setSelectedTbaleRowID] = useState("");
  const [customer, setCustomer] = useState("");
  const [rate, setRate] = useState("");
  const [currency, setcurrency] = useState("");
  const [exRate, setEXRate] = useState("");
  const [cost, setCost] = useState(0.0000);
  const [quoat, setQuoat] = useState(0.0000);
  const [rRate, setRRate] = useState("");
  const [cDate, setCDate] = useState("");
  const [volume, setVolume] = useState("");
  const [custName, setcustName] = useState("");
  const [supName, setSupName] = useState("");
  const [supCode, setSupCode] = useState("");
  const [currentRRate, setCurrentRRate] = useState("");
  const [currencyName, seturrencyName] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [vatPersontage, setVatPersontage] = useState(0);
  const [, setOptionalVatStatus] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [validateFailedInputsList, setValidateFailedInputsList] = useState([]);
  const fieldsToCheck = [
    { name: 'customer', label: 'Customer' },
    { name: 'rate', label: 'Rate' },
    { name: 'currency', label: 'Currency' },
    { name: 'exRate', label: 'Exchange Rate' },
    // { name: 'cost', label: 'Cost' },
    // { name: 'quoat', label: 'Quoat' },
    { name: 'supCode', label: 'Supplier' },
    { name: 'rRate', label: 'Rate Remark' },
    { name: 'cDate', label: 'Credit Days' },
    { name: 'volume', label: 'Volume' },
  ];

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const valueToCostHadle = (event) => {
    setCost(event.target.value);
  };

  const valueToExRateHadle = (event) => {
    setEXRate(event.target.value);
  };

  const valueToSupplierHadle = (event) => {
    setSupCode(event.value);
    setSupName(event.label);
  };

  const valueToCustomerHadle = (event) => {
    setCustomer(event.value);
    setcustName(event.label);
  };

  const valueToRateHadle = (event) => {
    setRate(event.value);
    setCurrentRRate(event.label);
  };

  const valueToCurruncyHadle = (event) => {
    setcurrency(event.value);
    seturrencyName(event.label);

  };

  const valueToQuoatHadle = (event) => {
    setQuoat(event.target.value);
  };

  const valueToRateRemakeHadle = (event) => {
    setRRate(event.target.value);
  };

  const valueToCreditDaysHadle = (event) => {
    setCDate(event.target.value);
  };

  const valueToCreditVolumeHadle = (event) => {
    setVolume(event.target.value);
  };

  const valueToOptionalVatAmount = (event) => {
    setVatPersontage(event.target.value);
  };

  const setVAT = (event) => {
    const selectedTax = props.pageData?.taxes_data.find(
      (item) => item.id === parseInt(event.target.id)
    );
    if (event.target.checked === true && selectedTax) {
      setOptionalVatStatus(true);
      setVatPersontage(0);
      setTaxList([...taxList, selectedTax]);
    }
    if (event.target.checked === false) {
      setOptionalVatStatus(false);
      const updatedArray = taxList.filter((item) => item.id !== event.target.id);
      setTaxList(updatedArray);
    }
  };

  const setTableValue = async () => {

    const emptyFields = [];
    fieldsToCheck.forEach(field => {
      if (!eval[field.name] && eval(field.name) === 0) {
        emptyFields.push(field.label)
      }
    });
    setValidateFailedInputsList(emptyFields);
    if (emptyFields.length > 0) {
      emptyFields.forEach(emptyField => {
        toast.error(`${emptyField} can not be empty`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
    } else {

      let totCost = totalCost;
      let qouted = quoat * 1;
      let totalNBTQuote = 0;
      let totNBT = 0;
      let nbtPrec = 0;
      let vatPrec = 0;
      let totalVATQuote = 0;
      let totVATItem1 = 0;
      let allTotalCost = 0;
      const vatPersontage = 18;
      totCost = exRate * volume * cost;
      qouted = exRate * volume * quoat;

      taxList !== null ? taxList.forEach((item) => {

        if (item.tax_rate > 0) {
          if (item.tax === 'VAT') {
            if (vatPersontage > 0) {
              totVATItem1 = (exRate * volume * cost + totNBT) * (vatPersontage / 100);
              totalVATQuote = (exRate * volume * qouted + totalNBTQuote) * (vatPersontage / 100);
              vatPrec = vatPersontage / 100;
            } else {
              totVATItem1 = (exRate * volume * cost + totNBT) * (item.tax_rate / 100);
              totalVATQuote = (exRate * volume * qouted + totalNBTQuote) * (item.tax_rate / 100);
              vatPrec = item.tax_rate / 100;
            }
          }
          if (item.tax === "NBT") {
            totNBT = exRate * volume * cost * item.tax_rate;
            totalNBTQuote = exRate * volume * qouted * item.tax_rate;
            nbtPrec = item.tax_rate;
          }
        }
        allTotalCost = allTotalCost + totCost + totVATItem1;
      }) : console.log("Without tax");

      let difference = qouted - totCost;
      setTotalCost(totCost);

      appndTableValue(
        totCost,
        qouted,
        difference,
        totNBT,
        totalNBTQuote,
        nbtPrec,
        vatPrec,
        totVATItem1,
        totalVATQuote
      );
    }
  };

  const appndTableValue = async (
    totCost,
    qouted,
    difference,
    totNBT,
    totalNBTQuote,
    nbtPrec,
    vatPrec,
    totVATItem1,
    totalVATQuote,
    totAmount
  ) => {
    const updateTableVal = {
      customer_id: customer,
      rate_id: rate,
      currency_id: currency,
      TotalNBT: totNBT,
      nbt_percentage: nbtPrec,
      TotalNBTQuote: totalNBTQuote,
      TotalVATQoute: totalVATQuote,
      vat_percentage: vatPrec,
      TotalVAT: totVATItem1,
      exchange_rate: exRate,
      TotalCost: totCost,
      cost: cost,
      quote: quoat,
      TotalQuote: qouted,
      supplier_id: supCode,
      rate_remark: rRate,
      credit_days: cDate,
      volume: volume,
      SupplierName: supName,
      Difference: difference,
      CustName: custName,
      RateName: currentRRate,
      Currency: currencyName,
    };
    const updateTableLIst = props.tabledata.concat(updateTableVal);
    props.handleTableData(updateTableLIst);
  };

  const RemoveSelectedDataRow = async () => {
    const withoutRemovedTableData = props.pageValuesData?.booking_wise_rates_table_data.filter((obj, index) => index !== selectedTbaleRowID);
    props.handleTableData(withoutRemovedTableData);
    props.setpageValuesData({ ...props.pageValuesData, booking_wise_rates_table_data: withoutRemovedTableData });
    handleClose();
  };

  function setSelectedRow(rowid, row) {
    setRate(row.rate_id)
    setcustName(row.CustName);
    setVatPersontage(row.vat_percentage * 100);
    seturrencyName(row.Currency)
    setSelectedTbaleRowID(rowid);
    setCustomer(row.customer_id);
    setCurrentRRate(row.RateName);
    setcurrency(row.currency_id);
    setEXRate(row.exchange_rate);
    setCost(row.cost);
    setQuoat(row.quote);
    setSupCode(row.supplier_id);
    setRRate(row.rate_remark);
    setCDate(row.credit_days);
    setVolume(row.volume);
  }

  const headercolumns = [
    "Customer Name",
    "Rate Name",
    "Currency",
    "Exchange Rate",
    "Volume",
    "Cost",
    "Quote",
    "Total Cost",
    "Total Quote",
    "Rate ID",
    "Difference",
    "Credit Days",
    "Supplier Name",
    "Supplier ID",
    "NBT",
    "VAT",
    "NBT Quote",
    "VAT Qoute",
    "NBT Percentage",
    "VAT Percentage",
  ];
  const columns = [
    "CustName",
    "RateName",
    "Currency",
    "exchange_rate",
    "volume",
    "cost",
    "quote",
    "TotalCost",
    "TotalQuote",
    "rate_id",
    "Difference",
    "credit_days",
    "SupplierName",
    "supplier_id",
    "TotalNBT",
    "TotalVAT",
    "TotalNBTQuote",
    "TotalVATQoute",
    "nbt_percentage",
    "vat_percentage",
  ];

  const customersoptions = props.pageData?.customers_data !== undefined ?
    props.pageData?.customers_data.map((customerOptionItem) => ({
      value: customerOptionItem.id,
      label: customerOptionItem.customer_name,
    })) : [];

  const ratesOptions = props.pageData?.rates_data !== undefined ?
    props.pageData?.rates_data.map((rateOptionItem) => ({
      value: rateOptionItem.id,
      label: rateOptionItem.rate_description,
    })) : [];

  const currencyOptions = props.pageData?.currency_data !== undefined ?
    props.pageData?.currency_data.map((currencyOptionItem) => ({
      value: currencyOptionItem.id,
      label: currencyOptionItem.currency_description,
    })) : [];

  const suplierOptions = props.pageData?.suppliers_data !== undefined ?
    props.pageData?.suppliers_data.map((suplierOptionItem) => ({
      value: suplierOptionItem.id,
      label: suplierOptionItem.supplier_name,
    })) : [];

  const clearTableIputs = () => {
    setRate("")
    setcustName("");
    setVatPersontage("");
    seturrencyName("")
    setSelectedTbaleRowID("");
    setCustomer("");
    setCurrentRRate("");
    setcurrency("");
    setEXRate("");
    setCost("");
    setQuoat("");
    setSupCode("");
    setRRate(' ');
    setVolume("");
    setCDate(' ');
  }

  return (
    <div className={props.validateFailedInputsList.includes("booking_wise_rates_table_data") ? "border-red2 d-flex flex-column form-box overflow-x-scroll" : "d-flex flex-column form-box overflow-x-scroll"}>
      <Row>
        <Col>
          <Form.Group >
            <Form.Label>Customer <span className="RQField">*</span></Form.Label>
            <SelectDropdown
              options={customersoptions}
              onChange={valueToCustomerHadle}
              value={customer !== "" ? customersoptions.find(option => option.value === customer) : null}
              clName={validateFailedInputsList.includes("Customer") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group >
            <Form.Label>Rate <span className="RQField">*</span> </Form.Label>
            <SelectDropdown
              options={ratesOptions}
              onChange={valueToRateHadle}
              value={currentRRate !== "" ? ratesOptions.find(option => option.value === rate) : null}
              clName={validateFailedInputsList.includes("Rate") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group >
            <Form.Label>Currency <span className="RQField">*</span> </Form.Label>
            <SelectDropdown
              options={currencyOptions}
              onChange={valueToCurruncyHadle}
              value={currency !== "" ? currencyOptions.find(option => option.value === currency) : null}
              clName={validateFailedInputsList.includes("Currency") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group >
            <Form.Label>Exchange Rate <span className="RQField">*</span></Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              name="totvolume"
              value={exRate ? exRate : ""}
              onChange={valueToExRateHadle}
              className={validateFailedInputsList.includes("Exchange Rate") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group >
            <Form.Label>Cost</Form.Label>
            <Form.Control
              type="number"
              placeholder="0.0000"
              name="totvolume"
              value={cost ? cost : ""}
              onChange={valueToCostHadle}
            // className={validateFailedInputsList.includes("Cost") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group >
            <Form.Label>Quote</Form.Label>
            <Form.Control
              type="number"
              placeholder="0.0000"
              name="totvolume"
              value={quoat ? quoat : ""}
              onChange={valueToQuoatHadle}
            // className={validateFailedInputsList.includes("Quoat") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group >
            <Form.Label>Supplier <span className="RQField">*</span></Form.Label>
            <SelectDropdown
              options={suplierOptions}
              onChange={valueToSupplierHadle}
              value={supCode !== "" ? suplierOptions.find(option => option.value === supCode) : null}
              clName={validateFailedInputsList.includes("Supplier") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group >
            <Form.Label>Rate Remark <span className="RQField">*</span> </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="rate_remark"
              value={rRate ? rRate : null}
              onChange={valueToRateRemakeHadle}
              className={validateFailedInputsList.includes("Rate Remark") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group >
            <Form.Label>Credit Days <span className="RQField">*</span> </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="creditdays"
              value={cDate ? cDate : null}
              onChange={valueToCreditDaysHadle}
              className={validateFailedInputsList.includes("Credit Days") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group >
            <Form.Label>Volume <span className="RQField">*</span></Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              name="valume"
              value={volume ? volume : ""}
              onChange={valueToCreditVolumeHadle}
              className={validateFailedInputsList.includes("Volume") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2">
        {
          props.pageData?.taxes_data !== undefined
            ? props.pageData?.taxes_data.map((taxItem) =>
              taxItem.active_status === 1 ? (
                <Col key={taxItem.id}>
                  <Form.Group controlId="">
                    <Form.Check
                      type="checkbox"
                      label={taxItem.tax}
                      id={taxItem.id}
                      value={taxItem.tax_rate}
                      onChange={setVAT}
                    />
                  </Form.Group>
                </Col>
              ) : ("")
            )
            : ""}
        {
          vatPersontage ? <Col>
            <Form.Group >
              <Row className="position-relative">
                <Col className="booking-Vat-pecentage-input"><Form.Control
                  type="text"
                  placeholder=""
                  name="optionalVat"
                  disabled="true"
                  value={vatPersontage ? vatPersontage : ""}
                  onChange={valueToOptionalVatAmount}
                  className="booking-Vat-pecentage-input"
                /></Col>
                <Col className="position-absolute booking-Vat-pecentage"> <Form.Label className="booking-Vat-pecentage-mark">%</Form.Label></Col>
              </Row>
            </Form.Group>
          </Col> : ""
        }
        <Col className="d-flex">
          <Button
            className="ms-3"
            variant="primary"
            onClick={setTableValue}
            type="submit"
          >
            Add
          </Button>
          <Button
            className="ms-3"
            variant="primary"
            onClick={handleShow}
            type="submit"
          >
            Delete
          </Button>
          <Button
            className="ms-3"
            variant="primary"
            onClick={clearTableIputs}
            type="submit"
          >
            Clear
          </Button>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <BillTable
            data={props.pageValuesData?.booking_wise_rates_table_data ? props.pageValuesData?.booking_wise_rates_table_data : ""}
            columns={columns}
            headercolumns={headercolumns}
            setSelectedRow={setSelectedRow}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Form.Group className="mb-1">
            <Form.Label>
              Total cost{" "}
              {props.calculateTotalCost() + props.calculateTotalVAT() > 0
                ? (props.calculateTotalCost() + props.calculateTotalVAT()).toFixed(2)
                : props.pageValuesData?.total_cost}
            </Form.Label>
          </Form.Group>

        </Col>
        <Col>
          <Form.Group className="mb-1">
            <Form.Label>NBT {props.calculateTotalNBT()}</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1" >
            <Form.Label>VAT {props.calculateTotalVAT().toFixed(2)}</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1">
            <Form.Label> Total Quote {props.calculateTotalQuote().toFixed(2)} </Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1">
            <Form.Label>
              Total VAT Quote {props.calculateTotalVATQuote().toFixed(2)}
            </Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1" >
            <Form.Label>
              Difference {props.calculateTotalQuote() && props.calculateTotalCost() ? (props.calculateTotalQuote() - (props.calculateTotalCost() + props.calculateTotalVAT())).toFixed(2) : ""}
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure You want to Delete This Data Row ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={RemoveSelectedDataRow}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomerandSupplierinfo;
